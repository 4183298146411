/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
:root {
  --primary-color: #932C0E;
  --secondary-color: #F0F0F0;
  --icon-color: #707070;
  --icon-bg-color: #E8D1CA;
  --icon-check-color: #13A22E;
  --secondary-minor-color: #F3F3F3;
  --primary-border-color: rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.iconStyle{
  font: size 20px !important;
  color:var(--primary-color) !important;
  margin-left:4px !important;
 }
.header-text {
  background-color: var(--secondary-color);
  border-radius: 5px;
}
.backBtn {
  height:2.3rem !important;
  color:var(--primary-color) !important;
  border:1px solid var(--primary-color) !important
}
.scrollType{
  max-height: 27vh !important;
  overflow: auto !important;
}
.scroll {
  max-height: 65vh !important;
  overflow: auto !important;
}
.modalHeading{
  position: sticky !important;
  top: 0 !important;
  z-index:9;
}
.rightSubHead {
  position: sticky !important;
  top: 3rem !important;
  z-index:9 !important;
}
table thead th {
  border-bottom:none!important;
}
.noteField{
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  width: 300px !important;
}
.mat-radio-outer-circle {
  width: 17px !important;
  height: 17px !important;
}

.modalStyle {
  position: sticky;
  top: 0;
  z-index: 10;
}


.mat-radio-inner-circle {
  width: 19px !important;
  height: 19px !important;
  left: -1px !important;
  top: -1px !important;
}

.rear-text {
  font-size: 17px;
  font-weight: normal;
  margin: 13px 0 10px 0px;
  color: var(--primary-color);
  text-transform: uppercase;
  width: 100%;
  float: left;
}

.truck-inspection h3 {
  font-size: 17px;
  font-weight: normal;
  color: #777;
  margin: 10px 0 11px 0;
}

.checkbox-grpbox {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 10px 0px;
  margin-top: 9px;
}

.checkbox-grpbox .iner-chek {
  border-bottom: 1px solid #ddd;
}

.checkbox-grpbox .mat-checkbox-layout {
  display: block;
}

.checkbox-grpbox span.mat-checkbox-label {
  float: right;
}

.innernav-tabs li a {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #505461 !important;
  cursor: pointer;
  font-size: 15px;
  padding: 8px 10px;
  font-weight: 500;
  display: block;
}

.innernav-tabs li a.active,
.innernav-tabs li a:focus,
.innernav-tabs li a:hover {
  border-bottom: 2px solid var(--primary-color) !important;
  color: #505461 !important;
}

.theme-text-color {
  color: var(--primary-color);
}

.inputwithtimeicon {
  position: relative;
}

.timeicon {
  position: absolute;
  top: 12px;
  right: -4px;
  opacity: 0.6;
}

.boxtype-lft-right {
  margin-top: 10px;
}

.inputwithplusminus {
  position: relative;
}

.actnbtn-pls {
  position: absolute;
  right: 15px;
  top: 8px;
}

.timepicker-backdrop-overlay {
  z-index: 99999 !important;
}

.timepicker-overlay {
  z-index: 999999 !important;
}

.btn-success-bg .mat-icon-button {
  background: none !important;
  border: none !important;
  color: #555;
}

.form-label-block {
  display: block;
  font-size: 15px;
  font-weight: normal;
  color: #777;
}

.metismenu li a i.aroicon {
  width: auto !important;
  float: right;
  margin-left: 4px;
}

.modal em {
  font-size: 14px;
  font-style: normal;
}

select:focus-visible,
select:focus {
  outline: none;
}

.mat-radio-group .mat-radio-label {
  margin-right: 14px;
}

.mat-radio-group .mat-radio-label .mat-radio-label-content {
  padding-left: 4px;
  color: #777;
}

.actnbtn {
  padding: 1px 5px;
  font-size: 15px;
  border-radius:5px;
  margin-right: 3px;
  width:fit-content;
  cursor: pointer;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  min-width: 24px;
  min-height: 24px;
  align-content: center;
}

.btn-danger {
  color: #dc3545 !important;
}
.btn-action {
color: var(--primary-color);
}

.btn-danger:hover {
  background: var(--secondary-color);
}
.badge {
  font-weight: normal;
}

.modal-subheading {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.mat-header-cell {
  color: #797979;
}

.mat-table select {
  border-radius: 4px;
  border: 1px solid#797979;
  padding: 2px 15px 2px 2px;
  color: #797979;
  background-repeat: no-repeat;
  background-position: 97% 52%;
  width: 110px;
}

/* This is to remove the arrow of select element in IE */
.mat-table select::-ms-expand {
  display: none;
}

.mat-table select {
  -webkit-appearance: none;
  appearance: none;
}

/* @-moz-document url-prefix(){
	.ui-select{border: 1px solid #CCC; border-radius: 4px; box-sizing: border-box; position: relative; overflow: hidden;}
  .ui-select select { width: 110%; background-position: right 30px center !important; border: none !important;}
} */

.uplimgedit .imgupinpt {
  position: absolute;
  opacity: 0;
  width: 30px;
  right: 0;
  cursor: pointer;
  height: 30px;
}

.fltrdrp i.fa.fa-angle-down {
  padding: 3px 0px 3px 7px;
  margin-left: 10px;
  position: relative;
}

.frvrrado .mat-radio-button {
  margin-right: 10px;
}

.upldimgcrle {
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  border: 4px solid #ccc;
  padding: 5px;
}

.modal-lg {
  width: 900px;
}

.upldimgcrle img {
  margin: auto;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.uplimgedit {
  position: absolute;
  top: 0px;
  z-index: 99;
  right: 0;
  font-size: 24px;
}

.imgupinpt {
  position: absolute;
}

.fltrdrp i.fa.fa-angle-down::after {
  border-left: 1px solid #fff;
  position: absolute;
  top: 0px;
  left: -2px;
  width: 1px;
  height: 100%;
  content: "";
}

.list-in-mright .table thead th {
  font-size: 15px;
  font-weight: 600;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  line-height: 23px;
  top: 1.2em;
}


.rangpickerinpt::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}
.schedlemodle .mat-checkbox-frame {
  border-radius: 50%;
}

.schedlemodle .mat-checkbox-background {
  border-radius: 50%;
}

.timezon th,
.timezon td {
  padding: 5px;
  font-size: 13px;
}

.timezon .mat-checkbox {
  display: block;
}

th.mat-header-cell {
  max-width: 250px !important;
  overflow-wrap: break-word;
  border: 1px solid #ccc !important;
  padding-left: 10px !important;
}

td.mat-cell,
td.mat-footer-cell {
  border: 1px solid #ccc !important;
  padding-left: 10px !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  max-width: 250px !important;
  overflow-wrap: break-word;
}

.timezon .mat-checkbox-frame {
  background: #ccc;
  border-color: transparent;
}

.timezon .mat-checkbox-checkmark {
  display: none;
}

.datrngi {
  position: relative;
}

.donaro {
  margin-left: -20px;
  position: relative;
  color: #fff;
}

.donaro::after {
  border-left: 1px solid #fff;
  position: absolute;
  top: -2px;
  left: -8px;
  width: 1px;
  height: 20px;
  content: "";
}

.rangpickerinpt:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.rangpickerinpt::placeholder {
  color: #fff;
}

.flter {
  width: 200px;
  margin-top: -4px;
}

.flter .mat-form-field {
  max-width: 100%;
}

.button-act-grp .rangpickerinpt {
  font-size: 15px;
}

.modal-footer {
  padding-bottom: 0px;
}


.form-check input[type="checkbox"]:checked+.label-text:before {
  color: var(--primary-color) !important;
}

.comonlabel {
  font-weight: 300;
  color: #666;
  line-height: 1.125;
  margin-bottom: 1px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pament-cmpt {
  padding: 20px;
  max-width: 280px;
  overflow: hidden;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 5px;
}

.pmtcnt {
  text-align: right;
}

.pmtcnt p {
  color: #98a6ad;
}

.widget-bg-color-icon .bg-icon {
  height: 80px;
  width: 80px;
  float: left;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
}

.pmtcnt strong {
  color: #797979 !important;
  line-height: 30px;
  display: block;
  margin-bottom: 10px;
}

.widget-bg-color-icon .bg-icon .fa {
  font-size: 32px;
  line-height: 80px;
}

.margin10 {
  margin: 10px;
}

.md-drppicker .btn,
.md-drppicker td.active,
.md-drppicker td.active:hover {
  background: var(--primary-color) !important;
}

.tablecntimg {
  width: 40px;
  height: 40px;
}

.mb0,
.mb0 label {
  margin-bottom: 0px;
}

.rangpickerinpt {
  border: none;
  border-bottom: 1px solid #aaa;
  min-width: 240px;
  margin-top: 10px;
}

.rangpickerinpt:focus {
  outline: none;
}

.profile-info-page {
  border: 1px solid #ddd;
  margin-top: 10px;
}

.usrimg-sec {
  text-align: center;
  background: #f2f2f2;
  padding: 20px;
}

.usrimg-sec img {
  border-radius: 50%;
  padding: 3px;
  background: #fff;
  width: 70px;
  border: 1px solid #ddd;
  height: 70px;
  object-fit: contain;
}

.profile-info {
  padding: 10px 20px;
}

.profile-info li {
  padding: 5px;
}

.usrimg-sec strong {
  display: block;
  clear: both;
  margin-top: 10px;
  font-size: 16px;
}

.profile-info label {
  font-weight: 600;
}

.timepicker__header {
  background-color: var(--primary-color) !important;
}

.timepicker-button {
  color: var(--primary-color) !important;
}

.clock-face__number>span.active {
  background-color: var(--primary-color) !important;
}

.timepicker-overlay {
  --clock-hand-color: var(--primary-color) !important;
}

.clock-face__number[_ngcontent-c21]>span.active[_ngcontent-c21] {
  background: var(--primary-color) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary-color);
}

.padding-top20 {
  padding-top: 20px;
}

.ngx-editor-textarea {
  min-height: 200px !important;
}

.full-width-input-form .mat-form-field {
  width: 100%;
}

.comoninpt {
  border: none;
  border-bottom: 1px solid #888;
  width: 100%;
}

.comoninpt:focus {
  box-shadow: none;
  outline: none;
}

.loginsignup-form .mat-form-field {
  width: 100%;
}

.mat-file-upload .mat-card {
  padding: 4px;
}

.mat-file-upload .mat-icon {
  width: auto;
  font-size: 14px;
  margin: 5px;
}

.fileup .mat-primary {
  margin-right: 5px;
}

a {
  color: var(--primary-color);
}

.block {
  display: block;
}

.mt5 {
  margin-top: 5px;
}

.mr10 {
  margin-right: 10px;
}

p {
  font-size: 14px;
}

.cdk-overlay-container {
  z-index: 9999;
}

.loginsignup-form {
  margin-top: 50px;
  background: #f2f2f2;
  padding: 40px;
  border: 1px solid #ddd;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 22px #555;
  border-radius: 4px;
  margin: 30px auto;
  position: relative;
  max-width: 400px;
}

.modal .mat-form-field {
  width: 100%;
}

.login-logo {
  text-align: center;
}

mat-label {
  background-color: #ffffff !important;
  z-index: 9999;
}

.loginsignup-form h3 {
  margin: 20px 0px;
  color: #444;
  font-weight: 600;
  font-size: 26px;
}

.loginsignup-form h3 strong {
  color: var(--primary-color);
}

.account-pages {
  background: linear-gradient(to bottom, #2c4e80 0%, #dabcb4 100%) !important;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
}

.btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 0.9;
}

.mt20 {
  margin-top: 20px;
}

.mt10 {
  margin-top: 10px;
}

/*check box and radio button*/
.form-check label {
  position: relative;
  cursor: pointer;
}

.form-check input[type="checkbox"],
.form-check input[type="radio"] {
  position: absolute;
  right: 9000px;
}

.form-check {
  padding-left: 0px;
}

/*Check box*/
.form-check input[type="checkbox"]+.label-text:before {
  content: "\f096";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
}

.form-check input[type="checkbox"]:checked+.label-text:before {
  content: "\f14a";
  color: #2980b9;
  animation: effect 250ms ease-in;
}

.form-check input[type="checkbox"]:disabled+.label-text {
  color: #aaa;
}

.form-check input[type="checkbox"]:disabled+.label-text:before {
  content: "\f0c8";
  color: #ccc;
}

/*Radio box*/

.form-check input[type="radio"]+.label-text:before {
  content: "\f10c";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
}

.form-check input[type="radio"]:checked+.label-text:before {
  content: "\f192";
  color: #8e44ad;
  animation: effect 250ms ease-in;
}

.form-check input[type="radio"]:disabled+.label-text {
  color: #aaa;
}

.form-check input[type="radio"]:disabled+.label-text:before {
  content: "\f111";
  color: #ccc;
}

/*Radio Toggle*/

.toggle input[type="radio"]+.label-text:before {
  content: "\f204";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 10px;
}

.toggle input[type="radio"]:checked+.label-text:before {
  content: "\f205";
  color: #16a085;
  animation: effect 250ms ease-in;
}

.toggle input[type="radio"]:disabled+.label-text {
  color: #aaa;
}

.toggle input[type="radio"]:disabled+.label-text:before {
  content: "\f204";
  color: #ccc;
}

@keyframes effect {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.3);
  }

  75% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

/*end checkbox and radio button*/

html,
body {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400;
  background: #fff;
}

.mb20 {
  margin-bottom: 20px !important;
}

.delete-profiles button:hover,
.delete-profiles button {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff;
}

.padding-none {
  padding: 0px;
}

.comon-form {
  /*max-width: 800px;*/
  margin: 0px auto 30px;
  /*background: #f7f7f7;*/
}

.avatar-previews-s {
  max-width: 300px;
}

.comon-form .form-control {
  /*border-radius:3px !important;border:1px solid #ddd !important;*/
  box-shadow: none;
  padding-left: 0px;
  border-radius: 0px;
}

.comon-form h3 {
  padding: 10px 0px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  color: #303030;
  border-bottom: 1px solid #ccc;
}

.mt20 {
  margin-top: 20px;
}

.btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.floatleft {
  float: left;
}

.btntophdr {
  color: var(--primary-color);
  background: #fff;
  font-size: 19px;
}

.rig-btss {
  padding: 0px;
  margin-top: 6px;
  margin-bottom: 0;
  margin-left: 10px;
}

.rig-btss li {
  display: inline-block;
  padding: 2px 4px;
}

.gettuchform .mainlabel {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  line-height: 21px;
  margin-bottom: 4px;
}

.daterangesse .input-group-btn .btn {
  padding: 5px 12px 6px;
}

.gettuchform .customradio {
  margin-bottom: 4px;
  margin-left: 10px;
}

.radiotextsty {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: normal;
}

.traklist {
  float: right;
  margin-top: 0px;
  margin-left: 3px;
  width: 190px;
}

.traklist input {
  vertical-align: middle;
  margin-top: 0px !important;
}

.traklist span {
  margin: 5px;
  display: inline-block;
}

.customradio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customcheck .checkmark {
  border-radius: 2px;
}

.form-check-inline {
  float: left;
}

.inr-selects {
  float: left;
  margin: 0 0 0 18px;
}

/* Hide the browser's default radio button */
.customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #bebebe;
}

/* On mouse-over, add a grey background color */
.customradio:hover input~.checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.customradio input:checked~.checkmark {
  background-color: white;
  border: 1px solid #bebebe;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.customradio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.customradio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #51aafb;
}

.customcheck .checkmark:after {
  border-radius: 1px;
}

.export .dropdown-menu>li>a {
  display: block;
  padding: 3px 12px;
}

.create-left {
  margin: 19px 0 0 0;
  display: inline-block;
}

.create-left a {
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
}

.create-left a:hover {
  color: #fff;
  text-decoration: none;
  background: #545353;
  border: 2px solid #545353;
}

.export .dropdown-menu {
  min-width: 10px;
}

.fixed-table-loading {
  display: none;
}

.responsive-logo {
  display: none;
}

.rectangle #tawkchat-minified-wrapper {
  display: none !important;
}

.floatright {
  float: right;
}

.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.time-list ul {
  margin: 0;
  padding: 7px 5px;
  background: #fff;
  box-shadow: 1px 0px 8px #e0e0e0;
  border-bottom: 2px solid #cfcfcf;
}

.time-list ul li {
  list-style: none;
  display: inline-block;
  margin: 0 0px 0 0;
  border-right: 1px solid #a8a8a8;
  padding: 0 7px;
  line-height: 13px;
  color: #adadad;
}

.green-box {
  background: #c4e0a5;
  border-radius: 4px;
  height: 61px;
  width: 28px;
}

.time-list ul li:last-child {
  border-right: 0 !important;
}

#myInput {
  background-image: url("/images/searchicon.png");
  background-position: 7px 8px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 14px;
  padding: 9px 20px 7px 40px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.upper-search-box {
  padding: 0;
}

#search-list {
  background: #fff;
  overflow: hidden;
}

.admin-name {
  display: inline-block;
  margin: 2px 0 0 0;
}

#search-list .admin-name {
  vertical-align: top;
  margin-right: 8px;
}

#search-list .driver-box {
  width: 71%;
  float: none;
}

.available-box ul {
  margin: 0;
  padding: 8px 0 0 0;
}

#search-list li {
  border-bottom: 1px solid #eaeaea;
  display: inline-block;
  padding: 4px 0 4px 0;
}

#search-list li:last-child {
  border-bottom: 0;
}

.map-list {
  margin: 20px 0 0 0;
  display: inline-block;
}

.map-list li a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px 10px;
}

.map-list ul {
  margin: 0;
  padding: 0;
}

.map-list ul li {
  list-style: none;
  display: inline-block;
}

.available-box ul li {
  color: #9c9c9c;
  margin: 0 0 5px 0;
  border: 1px solid #c9c9c9 !important;
  display: inline-block !important;
  width: 46%;
  line-height: 11px;
  padding: 2px !important;
}

.available-box ul li a {
  font-size: 10px;
  color: #9c9c9c;
  font-weight: normal;
}

.modaltableorverflow {
  overflow-x: auto;
}

.modaltableorverflow .table {
  min-width: 1780px;
}

.modaltableorverflow .table th {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

span#cat_name_text,
#cat_name_text_1 {
  text-transform: lowercase;
}

.form-group .form-control {
  box-shadow: none;
  border-radius: 0px;
}

.catsss-action {
  float: right;
  padding-left: 0px;
}

.catsss-action a {
  padding: 4px 5px;
  display: inline-block;
}

.modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/**********************right-modal*************/
.right-lightbox .modal-header {
  background: var(--primary-color);
  padding: 8px 15px;
}

.right-lightbox .modal-title {
  color: #fff;
padding-bottom:3px !important;
  align-items: flex-end;
  margin-left:-3px;
}


.modal.right-lightbox .modal-dialog {
  top: 2px;
  position: fixed;
  margin: auto;
  width: 550px;
  /*max-height:500px;*/
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right-lightbox .modal-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.right-lightbox .modal-body {
  padding: 0px 12px 30px 15px;
}

.modal.right-lightbox.fade .modal-dialog {
  right: -450px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right-lightbox.fade.show .modal-dialog {
  right: 0;
}

.modal-content {
  border-radius: 0;
  border: none;
  padding-bottom: 10px;
}

.right-lightbox .close {
  opacity: 1;
  border-radius: 20px;
  padding: 5px 10px;
  text-shadow: none;
  background: var(--primary-color);
  color: #fff;
  margin-top: 0px !important;
  float: right;
}

.right-lightbox .modal-body {
  padding-top: 0px;
}

.drop-ellips {
  float: right;
  margin-right: 20px;
  margin-top: 6px;
}

.drop-ellips button {
  padding: 5px;
  background: none;
  box-shadow: none;
  border: none;
}

.drop-ellips.btn-group.open .dropdown-toggle {
  box-shadow: none;
  border: none;
}

.drop-ellips .dropdown-menu {
  left: auto;
  right: 0px;
}

.drop-ellips .dropdown-menu li {
  padding: 5px 10px;
}

.list-in-mright {
  padding: 0px;
  max-height: 380px;
  margin-top: 20px;
}

.list-in-mright li {
  list-style: none;
  display: block;
  padding: 3px 5px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

.list-in-mright {

.detail-header {
  font-size: 15px;
  float: left;
  font-weight: 600;
  color: #535353;
  font-family: "Open Sans", sans-serif;
}
.caption {
  font-size: 15px;
    word-wrap: break-word;
    padding-left:10px;

}
}

.list-in-mright li .caption {
  font-size: 15px;
  float: left;
  font-weight: 600;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

.list-in-mright li .value {
  font-size: 15px;
  float: right;
  word-wrap: break-word;
  width: 300px;
  text-align: right;
}

.tabling-boxt6 {
  background: #f2f2f2;
  margin: 0px -15px;
}

.list-in-mright li .value .description {
  text-align: justify;
  line-break: anywhere;
}

.tabling-boxt6 .nav>li>a {
  padding: 7px 16px;
  display: block;
  border: none !important;
  border-bottom: 2px solid transparent !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.tabling-boxt6 .nav-tabs>li>a.active,
.tabling-boxt6 .nav-tabs>li>a:hover {
  display: block;
  border: none !important;
  border-bottom: 2px solid var(--primary-color) !important;
  background: none !important;
  color: var(--primary-color) !important;
}

.list-in-buttnt {
  padding: 0px;
  margin-top: 20px;
}

.list-in-buttnt li {
  list-style: none;
  display: inline-block;
  padding: 8px 5px;
  overflow: hidden;
  padding: 5px;
}

.list-in-buttnt .btn {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.modal-backdrop.fade.in {
  background: rgba(0, 0, 0, 0.8) !important;
}

.btn-success{
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  padding: 8px 10px;
}

.btn-success.active,
.btn-success:active,
.btn-success:hover,
.open>.dropdown-toggle.btn-success {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  color: var(--primary-color) !important;
}

/****************************right-modal end****************/
/*********************left modal*******************/

.left-lightbox .modal-header {
  background: var(--primary-color);
  padding: 8px 15px;
}

.left-lightbox .form-control {
  padding-left: 0px !important;
}

.left-lightbox .phoneNumber {
  padding-left: 13px !important;
}

.left-lightbox .modal-title {
  color: #fff;
  margin-top: 3px;
}

.modal.left-lightbox .modal-dialog {
  position: fixed;
  margin: auto;
  width: 530px;
  max-height: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.left-lightbox .close {
  opacity: 1;
  border-radius: 20px;
  padding: 5px 10px;
  text-shadow: none;
  background: #337ab0;
  color: #fff;
  margin-top: 0px !important;
}

.modal.left-lightbox.fade .modal-dialog {
  left: -530px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left-lightbox.fade.in .modal-dialog {
  left: 0;
}

.textarea43 {
  min-height: 80px;
}

.form-control {
  box-shadow: none;
}

/************************left modal end******************/
/*****************************product page*********************/
.pac-logo {
  z-index: 9999;
}

/***********loder************/
.loader-overlay {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
}

.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid var(--primary-color);
  border-bottom: 7px solid var(--primary-color);
  width: 80px;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate(0, -50%);
  top: 50%;
  z-index: 99;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/************************************/

.product-ss5 {
  margin-top: 20px;
}

.product-ss5 .nav>li>a {
  padding: 3px 20px;
  border: none !important;
  border-bottom: 2px solid transparent !important;
}

.product-ss5 .nav-tabs>li.active>a,
.tabling-boxt6 .nav-tabs>li>a:hover {
  border: none !important;
  border-bottom: 2px solid var(--primary-color) !important;
  background: none !important;
  color: #333 !important;
}

.listing-ul-main .fa {
  float: right;
}

.listing-ul-main {
  max-height: 500px;
  overflow-y: auto;
}

.panel-title4e {
  padding: 10px 10px;
  font-size: 16px;
  margin: 0px;
  background: var(--primary-color);
  color: #fff;
  font-weight: 600;
}

.pro4-inner {
  padding: 0px 0px;
  background: #fff;
}

.pro4-tab-inner4 {
  padding: 20px 8px;
}

.panel-title4e .fa {
  float: right;
  cursor: pointer;
}

.child-holder-li {
  color: #000;
  padding: 6px 10px;
  font-size: 17px;
  display: block;
  width: 100%;
  clear: both;
  border-bottom: 1px solid #ccc;
}

.child-holder-li:hover,
.child-holder-li.active {
  background: #ddd;
  cursor: pointer;
}

.child-holder-li span,
.child-holder-li .fa {
  cursor: pointer;
}

.child-holder-li span {
  max-width: 88%;
  display: inline-block;
  font-size: 14px;
}

.child-holder-li .cld-name {
  margin: 0px 10px;
  display: inline-block;
  font-size: 14px;
  min-width: 140px;
}

.child-holder-li span.child-hold-img {
  max-width: 50px;
  display: inline-block;
  margin: 0px 20px;
}

.pro-li-img {
  float: right;
}

.list-pros-ul-child {
  display: none;
  transition: 0.2s;
}

.child-holder-li.active .list-pros-ul-child {
  display: block;
}

.list-pros-ul-child li a {
  color: #000;
  padding: 8px 10px;
  display: block;
}

.list-pros-ul-child li a:hover,
.list-pros-ul-child li a.active {
  background: #eee;
}

.modal-header {
  background: var(--primary-color);
  color: #fff;
  align-items: flex-end;
}

/**********************file upload****************/
.show-image-part {
  border: 1px solid #ddd;
  padding: 4px;
  max-height: 236px;
  text-align: center;
}

.show-image-part img {
  max-height: 223px;
}

.files-impt input {
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

  text-align: center !important;
  width: 100% !important;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0px;
  opacity: 0;
}

.files-imptinput:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}

.files-impt {
  position: relative;
  outline: 2px dashed #92b0b3;
  min-height: 230px;
  width: 100%;
}

.files-impt:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files-impt:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: var(--primary-color);
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

/***************************end file upload***********/

/************/
.custom-check5r5 {
  float: right;
  margin-top: 0px;
}

.action-btngroup {
  float: right;
  margin-bottom: 20px;
}

.action-btngroup .btn {
  padding: 7px 29px;
  border-radius: 38px;
  margin-left: 10px;
}

.pro-comon label {
  font-weight: normal;
}

.pro-comon .form-control {
  min-height: 40px;
  padding-left: 0px;
  box-shadow: none;
  border-radius: 0px !important;
}

.pro-comon .modal-body {
  padding-bottom: 30px;
}

.pro-comon .modal-header button.close {
  margin-top: -2px !important;
  background: #fff;
  color: #000;
  padding: 6px 10px;
  opacity: 1;
  border-radius: 59%;
}

.full-width-act-grp {
  width: 100%;
  display: inline-block;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file .fa {
  font-size: 50px;
  margin-right: 10px;
  vertical-align: middle;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.checkbox.custom-check5r5 label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox.custom-check5r5 .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}

.checkbox.custom-check5r5 .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.checkbox.custom-check5r5 label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox.custom-check5r5 label input[type="checkbox"]:checked+.cr>.cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox.custom-check5r5 label input[type="checkbox"]:disabled+.cr {
  opacity: 0.5;
}

/**********************check send***************/

.one-text input[type="radio"] {
  opacity: 0;
}

.checkround {
  border: 2px solid #0a80ea;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
  vertical-align: middle;
  margin-right: 7px;
}

.allocation-images li.active .checkround {
  border: none;
}

.allocation-images li.active .checkround::before {
  content: "\f058";
  color: #0a80ea;
  font-size: 17px;
  position: absolute;
  top: -4.9px;
  font-family: fontawesome;
  left: -1px;
  animation: effect 250ms ease-in;
}




.tab-pane .bootstrap-select .btn:hover,
.tab-pane .bootstrap-select .btn:focus {
  background: #fff;
}

.pricing-sledtss {
  padding: 20px 0px;
  background: #fff;
}

.diff-geo {
  margin: 5px 0px 10px;
}

.prod-tabing-conted .input-group-addon {
  background: #eee;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: 100% !important;
}

.input-phonerrr .btn {
  padding: 5px 12px 6px !important;
}

.input-phonerrr input#phoneNumber {
  border: 1px solid #ccc !important;
  border-radius: 0px 3px 3px 0px;
}

.input-phone-light .btn {
  padding: 5px 12px 6px !important;
}

.input-phone-light input#phoneNumber {
  border: 1px solid #ccc !important;
  border-radius: 0px 3px 3px 0px;
}

@media screen and (max-width: 767px) {
  .table-responsive-mobile {
    width: 100%;
    overflow-x: auto;
    position: relative;
  }

  .table-responsive-mobile .table {
    min-width: 900px;
    margin-top: 40px;
    display: inline-block;
  }

  .custom-datatable-overright table tbody tr td {
    padding-left: 0px !important;
  }

  .table-responsive-mobile .pull-right.search {
    width: 100%;
    max-width: 80%;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }
}

@media screen and (max-width: 500px) {
  .modal.right-lightbox .modal-dialog {
    width: 97%;
  }

  .modal.right-lightbox.fade .modal-dialog {
    right: -97%;
  }

  .tabling-boxt6 .nav>li>a {
    padding: 7px 11px;
  }

  .tabling-boxt6 .nav-tabs {
    width: 89%;
  }

  .list-in-mright li .caption {
    float: left;
    width: 35%;
  }

  .list-in-mright li .value {
    width: 64%;
  }

  .modal.right-lightbox .modal-body {
    padding: 0px 10px 30px;
  }

  .tabling-boxt6 {
    margin: 0px -10px;
  }

  .custom-datatable-overright .btn {
    padding: 6px 7px !important;
  }
}

/**********************product page end****************************************/
.button-act-grp {
  float: left;
  margin-top: 14px;
}

.bokingactnbtn {
  margin-top: -5px;
}

@media screen and (max-width: 767px) {
  .sparkline13-graph .drop_div button.btn .fa {
    width: 25px;
  }
}

.driver-box {
  display: inline-block;
  margin: 5px 0 0 0;
  float: right;
}

.driver-box h4 {
  font-size: 12px;
  color: #9c9c9c;
  font-weight: 600;
  margin: 0 0 11px 0;
}

.driver-box h5 {
  font-size: 13px;
  color: #9c9c9c;
  font-weight: normal;
  margin: 0 0 5px 0;
}

.search-list ul {
  margin: 0;
  padding: 0;
}

.search-list ul li {
  list-style: none;
  display: inline-block;
}

.top-section2 li {
  border-right: 0 !important;
}

.topcheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.topcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.topcheck:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.topcheck input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.topcheck input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.topcheck .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.allow-padd {
  padding: 0;
}

.assign-box {
  border: 1px solid #dbd8d8 !important;
  padding: 9px;
  margin: 0 3px 10px;
}

.pricing-table .switch,
.breadcome-list-home .switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 21px;
}

.pricing-table .switch input,
.breadcome-list-home .switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: var(--primary-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.assign-box-bdr {
  border: 0 !important;
}

#manager .modal-footer button {
  border-radius: 60px;
  color: #fff;
}

#manager .modal-footer button:hover {
  background: #e3e4e6;
  border-color: #e3e4e6;
  color: #000;
}

.element-list ul {
  margin: 0;
  padding: 0;
}

.element-list ul li {
  list-style: none;
  display: inline-block;
  margin: 0 70px 0 0;
}

.assign-box {
  border: 1px solid #000;
}

.modal-content {
  border-radius: 5px;
}

#manager .modal-content {
  display: inline-block;
}

.element-list ul li a {
  font-size: 10px;
  font-weight: 400;
  color: #a1a1a1;
  text-transform: uppercase;
}

.element-list ul li span {
  list-style: none;
  display: inline-block;
  font-weight: 600;
  color: #878787;
  font-size: 13px;
}

.map-imge img {
  width: 100%;
  height: 396px;
  margin: 48px 0 0 0;
  border-radius: 4px;
}

.geo-form {
  background: #fff;
  display: inline-block;
  width: 100%;
  margin: 15px 0 0 0;
  border-radius: 3px;
  min-height: 260px;
  padding: 10px 0 0 0;
  box-shadow: 0 0px 7px #e3e3e3;
}

.uppergeo-forms label {
  font-size: 12px !important;
  color: #929292 !important;
  text-transform: uppercase;
  margin: 0 0 1px 0 !important;
  padding: 0 !important;
}

.uppergeo-forms input {
  border: 1px solid #d8d8d8 !important;
  margin: 5px 0 0 0;
  font-size: 13px !important;
  padding: 6px 6px;
}

.uppergeo-forms .form-group-inner {
  overflow: hidden;
}

.uppergeo-forms sup {
  color: red;
}

.uppergeo-forms .form-group-inner select {
  width: 100%;
  height: 33px !important;
  color: #a29f9f;
  margin: 5px 0 0 0;
}

#assigntask .modal-content {
  border-radius: 9px !important;
}

#assigntask .modal-dialog {
  width: 600px;
  margin: 190px auto 0;
}

#assigntask .modal-content h4 {
  font-weight: normal;
  color: #fff;
  margin-bottom: 0px;
  font-size: 20px;
}

#assigntask .modal-header {
  margin-bottom: 20px;
}

#assigntask .modal-body {
  text-align: left;
  padding: 0px 23px;
}

#assigntask .numbr-input {
  background: #e7e7e7;
}

#assigntask .modal-body .form-group-inner select {
  font-size: 12px;
  width: 100%;
  padding: 6px 0;
}

#assigntask .modal-footer .btn#assigntask .modal-footer a {
  font-size: 14px;
  color: #fff;
  background: var(--primary-color);
  padding: 7px 20px;
  border-radius: 60px;
}

#assigntask .modal-footer .btn:hover {
  color: #fff;
  opacity: 0.9;
}

#assigntask .modal-body label {
  font-size: 11px;
  color: #8a8a8a;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  width: 100%;
}

.add-manger {
  margin: 6px 0 0 0;
}

.add-manger button {
  background: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 60px;
  padding: 8px 14px;
  font-size: 14px;
}

.add-manger button:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-info:focus {
  color: #fff;
  background-color: var(--primary-color) !important;
}

#manager .modal-header h4 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  color: #878686;
  margin-top: 0;
  display: inline-block;
}

#manager .modal-header {
  border-bottom: 1px solid #e5e5e5;
}

.cancel-save {
  text-align: center !important;
}

#manager_form .form-group-inner label {
  font-size: 11px;
  color: #8a8a8a;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  width: 100%;
}

#manager_form .form-group-inner input {
  border: 1px solid #dbd8d8 !important;
  color: #a29f9f;
}

#manager_form .form-group-inner label sup {
  color: red;
  font-size: 11px;
}

#manager_form .form-group-inner select {
  width: 100%;
  height: 33px !important;
  color: #a29f9f;
}

.modal-footer {
  overflow: hidden;
}

.cancel-save button {
  background: #1494ff;
  color: #fff;
  font-size: 16px;
  border-radius: 60px;
  border: 1px solid #1494ff;
  margin: 0 0 10px 0;
}

.cancel-save button:hover {
  text-decoration: none;
  color: #5f5f5f;
  background: #e3e4e6;
  border-color: #e3e4e6;
}

.uppergeo-forms textarea {
  border: 1px solid #d8d8d8 !important;
  margin: 5px 0 0 0;
  width: 100%;
  resize: none;
  padding: 6px 6px 0;
  font-size: 13px;
}

.robert-bg {
  background: #fff;
  box-shadow: 0 6px 7px #e3e3e3;
  border-radius: 4px;
  padding: 34px 41px 13px;
  min-height: 150px;
}

.robert-bg h4 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #5d5d5d;
}

.add-card {
  text-align: right;
}

.robert-bg h6 {
  font-weight: normal;
  font-size: 17px;
  margin: 29px 0 0 0;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.plan-change {
  margin: 14px 0 0 0;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
}

.plan-change:hover {
  text-decoration: none;
  color: #000;
}

.account-bg {
  background: #fff;
  box-shadow: 0 3px 2px #e3e3e3;
  border-radius: 4px;
  padding: 13px 20px 13px;
}

.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}

.aligncenter {
  display: block;
  margin: 0 auto 15px;
}

a:focus {
  outline: 0px solid;
}

img {
  max-width: 100%;
  height: auto;
}

.fix {
  overflow: hidden;
}

p {
  margin: 0 0 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  font-weight: 700;
}

a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.main-form {
  background: #fff;
  box-shadow: 0 3px 2px #e3e3e3;
  border-radius: 4px;
  padding: 20px 20px;
  margin: 17px 0 0 0;
}

.mains-byes {
  position: relative;
}

.one-text {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.one-text label {
  font-weight: normal;
  color: #5c5a5a;
  vertical-align: middle;
}

.mains-request {
  background: #fff;
  overflow: hidden;
  padding: 10px 10px;
  margin: 10px 0 0 0;
  border-radius: 4px;
}

.mains-request .form-group-inner label {
  font-size: 13px;
  color: #999;
  padding: 10px 0px 0;
}

/*.mains-request .form-group-inner input {
    width: 98%;
}*/
.allocation-images ul li a {
  padding: 0;
}

.allocation-images ul .active {
  box-shadow: 0 2px 17px #6e6e6e;
}

.mains-request .form-group-inner h4 {
  font-weight: normal;
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 15px;
}

#profile_form .form-group-inner label sup {
  color: red;
  font-size: 11px;
}

.setting-padd {
  padding: 0;
}

.assign-text label {
  font-size: 17px;
  font-weight: normal;
  color: #6b6969;
}

a:visited,
a:link,
a:active {
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0 none;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

@font-face {
  font-family: "educate-icon";
  src: url("/fonts/educate-icon80d9.ttf?ka5vvx") format("truetype"),
    url("/fonts/educate-icon80d9.woff?ka5vvx") format("woff"),
    url("/fonts/educate-icon80d9.svg?ka5vvx#educate-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*----------------------------------------*/
/*  2. Helper Class
/*----------------------------------------*/
.mg-tb-30 {
  margin: 30px 0px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-tb-15 {
  margin: 15px 0px;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-b-10 {
  margin-bottom: 23px;
}

.mg-ub-10 {
  margin-bottom: 17px;
}

.mg-t-15 {
  margin-top: 15px;
}

.allocation-images nav ul li a:hover {
  background: inherit;
}

.datatable-ct {
  cursor: pointer;
}

.tab-content p {
  font-weight: normal;
  font-size: 14px;
  color: #999;
  margin: 11px 0 0 0;
  padding: 0 10px;
}

.delete-aal0s {
  padding: 3.3px 13px;
  background: var(--primary-color);
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  display: none;
  font-size: 16px;
}

.delete-aal0s:hover,
.delete-aal0s:focus {
  color: #fff;
}

#myModaless .modal-header .close {
  margin-top: 0 !important;
}

.modal-header .close {
  background: #fff;
  color: #000;
  padding: 5px 10px;
  opacity: 1;
  border-radius: 59%;
  margin: 0px;
}

.edit-btns {
  width: 100%;
  text-align: right;
  margin: 0 0 15px 0;
}

.edit-btns button {
  float: none !important;
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 4px !important;
  padding: 3px 8px !important;
}

.allocation-images {
  margin: 16px 0 0 0;
}

.allocation-images ul {
  margin: 20px 0;
  padding: 0;
}

.allocation-images ul li {
  list-style: none;
  display: inline-block;
  margin: 0 20px 0 0;
}

.allocation-images li a img {
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 7px #d1cece;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sparkline8-list h6 {
  margin: 27px 0 7px 0;
  font-weight: normal;
  color: var(--primary-color);
  font-size: 15px;
}

#assigntask ul {
  margin: 0;
  padding: 0;
}

#assigntask ul li {
  list-style: none;
  display: inline-block;
  margin: 0 29px 0 0;
}

.btn-success-bg button {
  background-color: var(--primary-color) !important;
  font-size: 14px;
  border-color: var(--primary-color) !important;
  color: #fff;
  border-radius: 3px;
  padding: 4px 8px;
}

#toolbar button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 3px;
}

.task-template h6 {
  padding: 5px 0 3px 0;
  font-weight: normal;
  color: #878787;
  font-size: 13px;
  margin: 0;
}

.datatable-ct {
  color: #a2a2a2;
  position: relative;
  overflow: inherit !important;
}

.action-list {
  position: absolute;
  background: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  width: 146px;
  box-shadow: rgb(136, 136, 136) 3px 3px 10px;
  left: -110px;
  z-index: 11;
  transition: 0.5s;
  padding: 5px 10px;
  display: none;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.datatable-ct:hover .action-list {
  display: block;
  opacity: 1;
}

.action-list li a {
  font-size: 13px !important;
  /*color: #fff !important;*/
}

.btn-success:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.allow-cation {
  position: relative;
  top: 9px !important;
  left: 8px !important;
  display: inline-block;
}

.create-btn button {
  float: right;
  margin: 0 13px 15px 0;
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 16px;
  background-color: var(--primary-color);
  border-color: #0e7add;
}

.direction-arrow {
  position: absolute;
  top: -10px;
  right: 6px;
  width: 10px;
  height: 10px;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.direction-arrow img {
  display: none;
}

#task .modal-dialog {
  margin: 0;
  margin-top: 10px;
}

.upload-browse label {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.upload-browse label:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.modal-title {
  font-size: 18px;
  color: #fff;
  font-weight: normal;

}

/*.action-list li a:hover{
    color: #ddd!important;
}*/
.main-form-mrg {
  margin: 0 !important;
  box-shadow: inherit !important;
  padding: 0 !important;
}

.right-arrow {
  cursor: pointer;
  color: #1494ff;
}

/*.action-list li a i:hover{
    color: #ddd!important;
}*/
#accordion .panel-heading {
  text-transform: inherit !important;
}

#accordion .panel-heading h4 a {
  font-weight: 500;
  font-size: 15px;
  text-transform: inherit;
  color: var(--primary-color);
}

#accordion .form-group-inner textarea {
  width: 100%;
  resize: none;
  border: 1px solid #dbd8d8 !important;
  padding: 11px;
}

#task .modal-footer {
  background: #fff;
}

#task .modal-footer button {
  border-radius: 60px;
  background: var(--primary-color);
  color: #ffff;
  border-color: var(--primary-color);
}

#task .modal-footer button:hover {
  background: #fff;
  color: #000;
  border-color: var(--primary-color);
}

.action-list li a i {
  /*color: #fff;*/
  padding: 0 0 0 8px;
  float: right;
  margin: 4px 0 0 0;
}

.action-list li {
  margin: 0 0 5px 0;
}

.datatable-ct i {
  position: relative;
  color: var(--primary-color);

}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-b-15 {
  margin-bottom: 20px;
}

.mt-b-30 {
  margin-bottom: 30px;
}

.bg-green {
  background: var(--primary-color);
}

.bg-red {
  background: #933ec5;
}

.bg-purple {
  background: #d80027;
}

/* sorting table  */
tr.mat-header-row,
tr.mat-footer-row,
tr.mat-row {
  height: auto !important;
}

th.mat-header-cell {
  padding: 7px !important;
  font-size: 15px;
}

td.mat-cell,
td.mat-footer-cell {
  padding: 6px !important;
  color: rgba(0, 0, 0, 0.54);
}

.mat-elevation-z8 {
  box-shadow: none;
}

.mat-table .mat-checkbox-layout {
  margin-bottom: 0px;
}

table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

.mat-option {
  transform: none;
}


/*  */
/*----------------------------------------*/
/*  3. All Nested Class
/*----------------------------------------*/
.product-sales-chart,
.analytics-info-cs,
.tranffic-als-inner,
.analytics-rounded,
.single-new-trend,
.personal-info-wrap,
.author-widgets-single,
.calender-inner,
.product-status-wrap,
.review-tab-pro-inner,
.income-dashone-total,
.analytics-edu-wrap,
.analytics-sparkle-line,
.analysis-progrebar,
.sparkline8-list,
.sparkline9-list,
.sparkline7-list,
.sparkline10-list,
.sparkline12-list,
.sparkline13-list,
.sparkline14-list,
.sparkline13-list,
.sparkline11-list,
.x-editable-list,
.code-editor-single,
.blog-details-inner,
.charts-single-pro,
.about-sparkline,
.sparkline-list,
.button-ad-wrap,
.tab-content-details,
.sparkline16-list,
.sparkline15-list,
.single-product-item,
.courses-inner,
.single-latest-item,
.profile-info-inner,
.contact-panel,
.widget-int-shape,
.social-media-edu {
  padding: 20px;
  background: #fff;
}

/*----------------------------------------*/
/*  4. left sidebar
/*----------------------------------------*/
.all-content-wrapper {
  margin-left: 219px;
  padding-bottom: 110px;
  transition: all 0.3s;
  margin-top: 20px;
}

.allocation-fence h3 {
  display: inline-block;
  margin-right: 10px;
  margin-top: -17px;
  vertical-align: middle;
}

.left-sidebar-pro {
  z-index: 9999;
  width: 240px;
}

.left-current h4 {
  font-weight: normal;
  font-size: 16px;
  color: #595959;
}

.left-current p {
  font-weight: normal;
  font-size: 14px;
  color: #6b6b6b;
}

.left-current p span {
  font-size: 17px;
  margin-left: 10px;
}

.curent-bdr {
  border-right: 1px solid #d2d2d2;
}

.product-pricing-page {
  margin-bottom: 80px !important;
}

.listing_tran li {
  margin-bottom: 10px;
}

.sms-pricing h3 {
  font-size: 14px;
  font-weight: normal;
  color: var(--primary-color);
  display: inline-block;
}

.sms-pricing ul {
  margin: 0;
  padding: 0;
  float: right;
}

.sms-pricing ul li {
  list-style: none;
  display: inline-block;
}

.sms-pricing ul li a {
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
}

.sms-pricing ul li a:hover {
  background: var(--primary-color);
  color: #fff;
}

#sidebar {
    box-shadow: inset 0px 0px 4px 0px #888 !important;
  min-width: 218px;
  background: #f2f2f2;
  color: #fff;
  position: fixed;
  transition: all 0.3s;
  z-index: 1000;
  height: 100%;
  top: 0;
  box-shadow: inset -5px 0px 5px #f2f2f2;
}

.main-sparkline13-hd h4,
.main-sparkline13-hd h1 {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  text-transform: uppercase;
  border-bottom: 1px solid #d8d8d8;
  padding: 14px 0px;
}

.sparkline13-list-padd {
  padding: 0;
}

.sparkline13-graph .drop_div button.btn span {
  min-width: 56px;
  display: inline-block;
}

.sparkline13-graph .drop_div button.btn .fa {
  padding: 7px 8px;
  border-left: 1px solid #fff;
  margin: -1px;
  margin-left: 7px;
  width: 25px;
  text-align: center;
  border-radius: 0px 3px 3px 0px;
}

.sparkline13-graph .drop_div button.btn .fa.fa-filter {
  padding: 7px 3px;
  border: none;
  width: auto;
  margin-left: 0px;
}

.sparkline13-graph .drop_div button.btn {
  padding: 2px 3px 2px 11px;
  min-width: 106px;
}

/*.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}
.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}*/

.import-upload {
  color: var(--primary-color);
  position: relative;
  background: #f7f7f7;
  padding: 5px;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  min-height: 210px;
  margin-bottom: 20px;
}

.import-upload p {
  margin-top: 10px;
  font-size: 18px;
}

.upload-icon-impt {
  height: 50px;
  margin: 70px auto;
  z-index: 1;
  text-align: center;
}

.upload-icon-impt .fa {
  font-size: 40px;
}

#imageUpload {
  position: absolute;
  left: 20px;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 20px;
}

/*
input#imageUpload::after {
    position: absolute;
    content: "\f1c5";
    width: 60px;
    height: 50px;
    z-index: 999;
    font-family: fontawesome;
}*/
.fixed-table-toolbar .bs-bars,
.fixed-table-toolbar .search,
.fixed-table-toolbar .columns {
  margin-top: 0px !important;
}

.button-drop-style-two li .check-ss {
  display: inline-block;
  margin: 0px 5px;
  vertical-align: middle;
}

.button-drop-style-two li label {
  font-weight: normal;
}

.drop_div .dropdown-menu {
  left: 0px;
  top: 30px;
  background: #fff;
  box-shadow: 0px 2px 3px #bbb;
  border: 1px solid #ccc;
}

.drop_div .dropdown-menu>li>a {
  padding: 6px 4px;
  color: #333;
}

.another-drop-pro-eight span {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.sp1 {
  background: #bbb;
}

.sp2 {
  background: #f2711c;
}

.sp3 {
  background: #a333c8;
}

.sp4 {
  background: #2185d0;
}

.sp5 {
  background: #fbbd08;
}

.sp6 {
  background: #e03997;
}

.sp7 {
  background: #a333c8;
}

.sp8 {
  background: #21ba45;
}

.sp8 {
  background: #a333c8;
}

/*.form-group-inner .form-control{border:1px solid #ccc !important;border-radius:3px;}*/
.breadcome-area {
  margin-bottom: 30px;
}

.pickup-address label {
  position: relative;
}

.pickup-address input[type="radio"] {
  position: absolute;
  right: 9000px;
}

/*Radio box*/

.pickup-address input[type="radio"]+.label-text:before {
  content: "\f10c";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  color: var(--primary-color);
  margin-right: 5px;
}

.pickup-address input[type="radio"]:checked+.label-text:before {
  content: "\f058";
  color: var(--primary-color);
  animation: effect 250ms ease-in;
}

@keyframes effect {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.3);
  }

  75% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }

  .fixed-table-body {
    overflow-x: inherit !important;
    overflow-y: inherit !important;
  }

  .modal .fixed-table-body {
    overflow: auto !important;
  }
}

@media screen and (min-width: 768px) {
  #search-list li .col-sm-6:first-child {
    padding-left: 0px;
  }

  #search-list li .col-sm-6:nth-child(2) {
    padding-right: 0px;
  }
}

#profile_form .form-group-inner label {
  font-size: 14px;
  font-weight: 600;
  color: #8a8a8a;
  margin: 0 0 8px 0;
  width: 100%;
}

#profile_form .form-group-inner input {
  /* border: 1px solid #dbd8d8 !important;*/
  color: #a29f9f;
  padding-left: 0px;
}

.ch-pass {
  text-transform: none !important;
}

#profile_form .form-group-inner select {
  width: 100%;
  height: 33px !important;
  color: #a29f9f;
}

.margin-btm-end {
  margin-bottom: 80px;
}

.choose-btn a {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 50px;
  padding: 2px 10px;
  margin: 35px 0 0 0;
  display: inline-block;
}

.choose-btn p {
  color: #969696;
  font-size: 10px;
  margin: 10px 0 0 0;
}

.cancel-btns {
  margin: 15px 0 0 0;
  padding: 0;
}

.cancel-btns button {
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
  margin: 0 0 0 15px;
  padding: 6px 23px;
}

.cancel-btns button:hover {
  background: #c5c5c5;
}

.main-choose {
  padding: 0;
}

.save-btns {
  text-align: right;
}

.business-box {
  border: 1px solid #ececec;
}

.buss-image img {
  width: 100%;
}

.pickup-address {
  padding: 15px 15px;
  display: inline-block;
}

.form-box h3 {
  font-size: 20px;
  font-weight: 500;
  color: #6b6b6b;
  font-family: "Roboto", sans-serif;
  margin: 0 0 25px 0;
}

.pick-radio-lft {
  float: left;
}

.pick-radio-rt {
  float: right;
  width: 91%;
}

.pick-radio-rt h4 {
  font-size: 19px;
  font-weight: 500;
  color: #565656;
  margin: 0 0 4px 0;
}

.pick-radio-rt p {
  font-size: 12px;
  font-weight: 500;
  color: #909090;
  margin: 0 0 4px 0;
}

.form-box h6 {
  font-size: 15px;
  font-weight: 500;
  color: #6b6b6b;
  font-family: "Roboto", sans-serif;
}

.form-language {
  margin: 15px 0 0 0;
}

.form-language h3 {
  font-size: 20px;
  font-weight: 500;
  color: #6b6b6b;
  font-family: "Roboto", sans-serif;
}

.sms-cntr button {
  border-radius: 40px !important;
  padding: 4px 23px !important;
}

.save-btns {
  margin-top: 20px;
}

.save-btns button {
  border-radius: 47px;
}

.delete-profiles {
  margin-bottom: 30px;
}

.delete-profiles button {
  margin: 0 10px 15px 0;
  border-radius: 40px;
}

.sparklinelist-sms {
  border-radius: 4px;
  border: 1px solid #dadada;
}

.save-btns button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.save-btns2 button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.delete-profiles button:hover {
  background: #ea0c34;
  border-color: #f90935;
}

.save-btns2 button {
  border-radius: 47px;
}

.mini-navbar .all-content-wrapper {
  margin-left: 80px;
  transition: all 0.3s;
}

.mini-navbar #sidebar {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.mini-navbar #sidebar li:last-child {
  margin-left: 9px;
}
.mini-navbar .mini-click-non {

  display: none;
}
.mini-navbar .icon {
 padding-left: 13px;
}


#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

/*#sidebar.active .sidebar-header strong {
    display: block;
}*/
#sidebar.active ul li .sidebar-right-icon {
  display: none;
}

.bar-button-pro {
  margin: 13px 0px;
  font-size: 16px;
  color: #fff;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

.sidebar-header {
  text-align: center;
}

.sidebar-header h3 {
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
}

/*#sidebar.active .sidebar-header .main-logo {
    display: none;
}*/
#sidebar.active .sidebar-header h3 {
  display: none;
}

#sidebar.active .sidebar-header p {
  display: none;
}

#sidebar ul li a .sidebar-right-icon {
  float: right;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro {
  width: 100%;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li {
  display: block;
  float: none;
  position: relative;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .left-menu-dropdown {
  left: 0px;
  top: 0px;
  opacity: 0;
  border-radius: 0;
  border: 0px solid #fff;
  box-shadow: none;
  background: #ebebeb;
}

.left-custom-menu-adp-wrap .dropdown-menu {
  position: relative;
  float: none;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .left-menu-dropdown a:hover {
  padding: 15px 30px;
  transition: all 0.4s ease 0s;
  background: #fff;

}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown {
  opacity: 1;
  left: 0px;
  z-index: 9999;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.chart-left-menu-std {
  top: 0px;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.form-left-menu-std {
  top: 0px;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.apps-left-menu-std {
  top: 0px;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.pages-left-menu-std {
  top: 0px;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a {
  display: block;
  padding: 15px 20px;
  color: #444;
  transition: all 0.4s ease 0s;
  font-size: 16px;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:hover,
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:focus,
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:active,
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:visited {
  background: #ebebeb;
  color: #444;
}

.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .indicator-right-menu {
  float: right;
  font-size: 12px;
}

#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown {
  left: 80px;
}

#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .mini-dn {
  display: none;
}

#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .big-icon {
  font-size: 20px;
}

#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li>a {
  padding: 20px 20px;
}

/*----------------------------------------*/
/*  4.1 Left Sidebar switcher
/*----------------------------------------*/
.menu-switcher-pro {
  transition: all 0.4s ease 0s;
}

.mini-navbar .menu-switcher-pro {
  transition: all 0.3s;
}

.menu-switcher-pro button {
  background: none;
  border: none;
}

.menu-switcher-pro button:hover,
.menu-switcher-pro button:focus,
.menu-switcher-pro button:active,
.menu-switcher-pro button:visited {
  background: none;
  border: none;
}

/*----------------------------------------*/
/*  5. Header Top
/*----------------------------------------*/
.header-top-menu .navbar-nav>li>a {
  padding: 20px 20px;
  color: #fff;
  font-size: 14px;
}

.header-top-menu .navbar-nav>li a .angle-down-topmenu {
  margin-left: 4px;
}

.header-top-menu .navbar-nav>li>a:hover {
  color: #03a9f4;
}

.header-top-menu .navbar-nav>li .dropdown-menu a {
  padding: 10px 20px;
  display: block;
  color: #303030;
}

.header-top-menu .navbar-nav>li .dropdown-menu a:hover {
  background: #2b2a2a;
}

.header-top-menu .navbar-nav>li .dropdown-menu {
  border: 0px solid #ccc;
  border: 0px solid rgba(0, 0, 0, 0.15);
  background-color: #303030;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
}

.header-right-info .navbar-nav>li .dropdown-menu {
  border: 0px solid #ccc;
  border: 0px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
}

.header-top-menu .nav>li>a:focus,
.header-top-menu .nav>li>a:hover,
.header-top-menu .nav>li>a:active {
  text-decoration: none;
  background-color: transparent;
}

.header-top-menu .nav .open>a,
.header-top-menu .nav .open>a:focus,
.header-top-menu .nav .open>a:hover {
  background-color: transparent;
}


.header-right-info .navbar-nav {
  float: right;
  padding: 17px 0px 10px;
}

.admin-logo {
  padding: 10px 0px;
}

.header-top-menu ul.header-top-nav li {
  display: inline-block;
  position: relative;
}

.header-top-menu ul.header-top-nav li ul.dropdown-header-top {
  position: absolute;
  top: 130%;
  left: -10px;
  width: 200px;
  background: #303030;
  opacity: 0;
  transition: all 0.5s ease 0s;
  z-index: 999;
  padding: 10px 0px;
}

.header-top-menu ul.header-top-nav li ul.dropdown-header-top.in {
  opacity: 1;
  top: 100%;
  transition: all 0.5s ease 0s;
}

.admin-project-icon {
  margin-left: 5px;
  font-size: 10px;
  color: #fff;
}

.form-group label {
  font-size: 15px;
  color: #666;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.usrimghdr {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 35px;
  padding: 2px;
}

.header-right-menu .btn {
  padding: 0px;
}

.header-top-menu ul.header-top-nav li .dropdown-header-top li {
  display: block;
}

.header-top-menu ul.header-top-nav li .dropdown-header-top li a {
  display: block;
  color: #fff;
  padding: 15px 20px;
}

.dropdown-header-top a {
  padding: 5px 10px;
  display: block;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
}

.header-top-menu ul.header-top-nav li .dropdown-header-top li a:hover {
  background: #03a9f4;
}

.header-top-menu ul.header-top-nav li a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
  padding: 18px 10px;
}

.header-right-info {
  display: initial;
}

.nav-tabs {
  padding-bottom: 0px !important;
}

.nav-tabs li .nav-link {
  padding: 4px 20px;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs>li>.nav-link.active {
  border-bottom: 2px solid var(--primary-color) !important;
  color: #000;
}

.map-list li a i {
  margin: 0 6px 0 0;
}

.map-list li a:hover {
  background: #000;
}

.search-padd {
  padding: 0;
  margin-top: 14px;
}

.search-padd input {
  background: #fff;
  height: 35px !important;
}

.search-padd .input-group-append {
  background: var(--primary-color);
  padding: 5px 8px 6px;
  border-top: 1px solid var(--primary-color);
}

.search-padd .input-group-append button {
  border: 0;
  background: 0;
  color: #fff;
  font-size: 14px;
}

.search-padd .nav-tabs {
  padding-bottom: 0px;
}

.search-padd .nav-tabs>li>a.active {
  color: #939393;
  cursor: default;
  background-color: #fafafa;
  border-bottom: 2px solid var(--primary-color);
  color: #000;
}

.search-padd .nav-tabs>li>a.active:hover {
  border-right: 0;
  border-left: 0;
  border-top: 0;
}

.search-padd .nav-tabs>li a {
  border-radius: 0 !important;
  font-weight: bold;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: poiner;
  text-transform: uppercase;
  font-size: 11px;
  color: #a8a8a8;
  padding: 12px 8px;
}

.search-padd .nav-tabs>li a:hover {
  border-bottom: 2px solid var(--primary-color);
  background: #fff;
}

.driver-imge {
  background: #007a6a;
  height: 30px;
  width: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  color: #ffff;
}

.status-list li {
  margin: 15px 0 0 0;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 15px 0;
  overflow: hidden;
}

.upper-driver {
  position: relative;
  display: inline-block;
  float: left;
}

.upper-text {
  display: inline-block;
  float: left;
}

.upper-text h3 {
  font-weight: 600;
  font-size: 14px;
  color: #444;
  margin: 2px 0 5px 15px;
}

.upper-text h2 {
  font-weight: normal;
  font-size: 14px;
  color: #9f9f9f;
  margin: 0 0 0 15px;
}

.tasks-nmbr {
  text-align: center;
}

.tasks-nmbr h3 {
  height: 25px;
  width: 25px;
  display: inline-block;
  font-weight: normal;
  color: var(--primary-color);
  font-size: 16px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  line-height: 23px;
}

.tasks-nmbr h5 {
  font-weight: normal;
  font-size: 13px;
  color: #9f9f9f;
}

.cntr-map {
  margin-top: 15px;
}

.status-icon {
  position: absolute;
  bottom: 0;
  background: #b7b7b7;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  text-align: center;
  line-height: 6px;
  right: -6px;
  border: 1px solid #eaeaea;
}

.status-icon i {
  font-size: 7px;
  color: #fff;
}

.header-right-info ul.header-right-menu li {
  display: inline-block;
}

.header-right-info ul.header-right-menu li .author-message-top,
.header-right-info ul.header-right-menu li .notification-author,
.header-right-info ul.header-right-menu li .author-log {
  position: absolute;
  top: 160%;
  left: -70px;
  width: 330px;
  background: var(--primary-color);
  text-align: left;
  opacity: 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 999;
}

.header-right-info ul.header-right-menu li .dropdown-header-top.author-log {
  width: 200px;
  padding: 10px 0px;
}

.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li {
  display: block;
}

.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a {
  padding: 10px 20px;
  display: block;
  color: var(--primary-color);
  font-size: 14px;
}

.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li .author-log-ic {
  margin-right: 10px;
}

.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:hover,
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:focus {
  background: var(--primary-color);
}

.header-right-info ul.header-right-menu li .author-message-top {
  left: -133px;
}

.header-right-info ul.header-right-menu li .notification-author {
  left: -134px;
}

.header-right-info ul.header-right-menu li .author-log {
  left: -2px;
}

.header-right-info ul.header-right-menu li.open .author-message-top,
.header-right-info ul.header-right-menu li.open .notification-author,
.header-right-info ul.header-right-menu li.open .author-log {
  opacity: 1;
  top: 170%;
  transition: all 0.5s ease 0s;
  visibility: visible;
}

.header-right-info ul.header-right-menu li ul.message-menu li a,
.header-right-info ul.header-right-menu li ul.notification-menu li a {
  margin: 20px 20px;
  display: block;
  text-decoration: none;
  color: #fff;
}

.header-right-info ul.header-right-menu li .message-view a,
.header-right-info ul.header-right-menu li .notification-view a {
  display: block;
  color: #ccc;
  font-size: 14px;
  border-top: 1px solid #383838;
  padding: 15px 0px;
  text-align: center;
  text-decoration: none;
}

.header-right-info ul.header-right-menu>li>a {
  display: inline-block;
  color: #fff;
  padding: 0px 0px 0px 20px;
  font-size: 20px;
  text-decoration: none;
  position: relative;
}

.indicator-nt {
  position: absolute;
  height: 5px;
  width: 5px;
  background: #03a9f4;
  border-radius: 50%;
  top: -5px;
  right: 0px;
}

.indicator-ms {
  position: absolute;
  height: 5px;
  width: 5px;
  background: #03a9f4;
  border-radius: 50%;
  top: -5px;
  right: -7px;
}

.header-right-info .admin-name {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  position: relative;
  top: -2px;
  margin-left: 2px;
}

.header-right-info .nav>li>a>img {
  width: 26px;
  border-radius: 50%;
}

.header-right-info .author-project-icon {
  color: #fff;
  font-size: 10px;
  position: relative;
  top: -4px;
  margin-left: 5px;
}

.header-right-info .message-author {
  position: relative;
}

.header-right-info .author-message-top:before,
.header-right-info .notification-author:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-bottom: 10px solid #343434;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  right: 50%;
  top: -9px;
  margin-right: -10px;
  z-index: 99;
}

.header-right-info .author-message-top li a {
  color: #fff;
}

.message-single-top h1,
.notification-single-top h1 {
  font-size: 16px;
  color: #303030;
  font-weight: 400;
  text-align: center;
  padding: 15px 0px;
  margin: 0px;
  border-bottom: 1px solid #383838;
}

ul.message-menu,
ul.notification-menu {
  height: 230px;
}

ul.message-menu li .message-img {
  float: left;
  width: 70px;
  margin-right: 10px;
}

ul.message-menu li .message-img img {
  border-radius: 50%;
}

ul.notification-menu li .notification-icon {
  float: left;
  width: 50px;
  height: 50px;
  background: #444;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  margin: 5px 10px 5px 0px;
}

ul.message-menu li .message-content,
ul.notification-menu li .notification-content {
  position: relative;
}

ul.message-menu li .message-content .message-date,
ul.notification-menu li .notification-content .notification-date {
  position: absolute;
  top: -2px;
  right: 0px;
  font-size: 13px;
  font-style: italic;
  color: #303030;
}

ul.message-menu li .message-content h2,
ul.notification-menu li .notification-content h2 {
  font-size: 14px;
  font-weight: 700;
  color: #303030;
}

ul.message-menu li .message-content p,
ul.notification-menu li .notification-content p {
  font-size: 14px;
  line-height: 20px;
  color: #303030;
}

.header-right-info ul.header-right-menu li.open>a {
  color: #fff;
}

.header-top-menu .navbar-nav>li.open>a {
  color: #03a9f4;
}

.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg.dropdown-menu {
  background: #343434;
}

.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.dropdown-menu {
  position: absolute;
  top: 170%;
  left: -358px;
  width: 400px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.175);
  padding: 20px;
}

.note-heading-indicate {
  margin-top: 20px;
}

.note-heading-indicate h2 {
  font-size: 18px;
  color: #303030;
}

.note-heading-indicate p {
  font-size: 14px;
  color: #303030;
}

.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:focus,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:hover .notes-img {
  background: #303030;
}

.notes-img {
  float: left;
}

.notes-img img {
  border-radius: 50%;
  width: 60px;
}

.notes-list-flow .notes-content {
  margin-left: 80px;
}

.notes-list-flow .notes-content p {
  margin: 0px;
  font-size: 14px;
  color: #303030;
  line-height: 22px;
}

.notes-list-flow .notes-content span {
  font-size: 13px;
  color: #303030;
}

.notes-list-area ul.notes-menu-list li {
  margin: 10px 0px;
}

.notes-menu-scrollbar,
.project-st-menu-scrollbar {
  height: 315px;
}

.project-st-list-area ul.projects-st-menu-list li a {
  color: #303030;
}

.project-st-list-area ul.projects-st-menu-list li {
  padding: 10px 0px;
}

.projects-st-heading {
  position: relative;
}

.projects-st-heading .project-st-time {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 13px;
  color: #303030;
}

.projects-st-heading h2 {
  font-size: 16px;
  color: #303030;
}

.projects-st-heading p {
  font-size: 14px;
  color: #303030;
  line-height: 22px;
  margin: 0px 0px 10px;
}

.projects-st-content p {
  font-size: 14px;
  color: #303030;
  margin: 0px 0px 5px;
}

.projects-st-content .progress {
  height: 5px;
  margin-bottom: 5px;
}

.projects-st-content.project-rating-cl .progress-bar {
  background-color: #f8ac59;
}

.projects-st-content.project-rating-cl2 .progress-bar {
  background-color: #03a9f4;
}

.project-list-flow,
.notes-list-flow {
  margin-right: 15px;
}

ul.setting-panel-list li {
  display: block !important;
}

.checkbox-title-pro h2 {
  display: block;
  font-size: 14px;
  margin: 0px;
  padding: 8px 0px;
  color: #303030;
  font-weight: 400;
}

.checkbox-title-pro {
  position: relative;
}

.ts-custom-check {
  position: absolute;
  right: 0px;
  top: 5px;
}

.onoffswitch {
  position: relative;
  width: 54px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 7px;
  background-color: var(--primary-color);
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 7px;
  background-color: #ffffff;
  color: #919191;
  text-align: right;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0;
  background: #ffffff;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0;
}

.header-top-menu .navbar-nav>li .dropdown-menu,
.header-right-info ul.header-right-menu li .author-message-top,
.header-right-info ul.header-right-menu li .notification-author,
.header-right-info ul.header-right-menu li .author-log,
.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg.dropdown-menu {
  background: #e12503;
}

.header-top-menu .navbar-nav>li .dropdown-menu a:hover,
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:hover,
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:focus,
.header-drl-controller-btn.btn-info:active:focus,
.btn-info:active:hover {
  background: var(--icon-bg-color);
}

.menu-switcher-pro .btn-info:active,
.menu-switcher-pro .btn-info:focus,
.menu-switcher-pro .btn-info:hover,
.menu-switcher-pro .btn-info:visited,
.header-drl-controller-btn.btn-info:active:focus {
  background: var(--primary-color);
}

.header-right-info ul.header-right-menu li .message-view a,
.header-right-info ul.header-right-menu li .notification-view a {
  border-top: 1px solid #c7290d;
}

.message-single-top h1,
.notification-single-top h1 {
  border-bottom: 1px solid #f6f8fa;
}

.header-right-info .author-message-top:before,
.header-right-info .notification-author:before {
  border-bottom: 10px solid #f6f8fa;
}

.header-top-menu .navbar-nav>li.open>a {
  color: #fff;
}

.header-top-menu .navbar-nav>li>a:hover {
  color: #fff;
}

.header-right-info .author-message-top:before,
.header-right-info .notification-author:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-bottom: 10px solid #e12503;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  right: 50%;
  top: -9px;
  margin-right: -10px;
  z-index: 99;
}

.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:focus,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:hover .notes-img {
  background: #e12503;
}

.breadcome-list {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.sr-input-func {
  position: relative;
  margin: 0;
  width: 180px;
  right: 0px;
  transition: 0.5s ease-out;
}

.sr-input-func a {
  position: absolute;
  top: 8px;
  right: -5px;
  color: #999;
  transition: 0.5s ease-out;
  font-size: 14px;
}

.sr-input-func:focus a {
  color: var(--primary-color);
}

/*----------------------------------------*/
/*  6.  breadcrumb
/*----------------------------------------*/
.breadcome-heading .form-control,
.sr-input-func .form-control:focus {
  border: 1px solid #ececec !important;
  font-size: 14px;
  height: 34px;
  color: #303030;
  padding-left: 20px;
  padding-right: 40px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  border-radius: 30px;
  width: 200px;
}

.breadcome-heading .form-control:focus {
  border: 1px solid var(--primary-color);
}

ul.breadcome-menu {
  padding-top: 8px;
}

ul.breadcome-menu {
  text-align: right;
}

ul.breadcome-menu li {
  font-size: 14px;
  display: inline-block;
  color: #444;
}

ul.breadcome-menu li a {
  color: #444;
}

ul.breadcome-menu li a:hover {
  color: #7266ba;
}

/*----------------------------------------*/
/*  10. Event
/*----------------------------------------*/
.calender-inner .fc-state-default {
  background: var(--primary-color);
  color: #fff;
  background-image: none;
  text-shadow: none;
  outline: none;
}

.radio .helper::after {
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #4099ff;
  border-color: #4099ff;
}

.radio.radiofill input:checked~.helper::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radio input:checked~.helper::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.product-edt-remove button {
  margin-top: 20px;
}

.product-edt-pix-wrap {
  padding: 40px 0px;
}

.pro-edt-img {
  margin-bottom: 20px;
}

.review-pro-edt button {
  padding: 5px 15px;
  background: #e12503;
  border-color: #e12503;
}

.analysis-progrebar-ctn .progress {
  height: 5px;
  margin-bottom: 10px;
}

.analysis-progrebar-ctn {
  padding: 20px;
  background: #fff;
}

.graph-rp {
  text-align: right;
}

.graph-rp-dl p {
  font-size: 14px;
  color: #999;
}

ul.cus-product-sl-rp li i {
  margin-right: 5px;
}

.pro-sl-hd span {
  font-size: 16px;
  color: #303030;
}

.actions-graph-rp a {
  font-size: 14px;
  color: #999;
  background: #f6f8fa;
  box-shadow: none !important;
}

.cus-gh-hd-pro h2 {
  font-size: 20px;
}

.analysis-progrebar-ctn h4,
.tranffic-als-inner h3 {
  font-size: 20px;
}

ul.two-part-sp li {
  display: inline-block;
  width: 49%;
  font-size: 20px;
  font-weight: 700;
}

.sp-cn-r .text-success {
  color: #303030;
}

.sp-cn-r i {
  color: var(--primary-color);
}

.sp-cn-r i,
.graph-two-ctn i,
.graph-three-ctn i,
.graph-four-ctn i {
  font-size: 14px;
}

.graph-two-ctn .text-purple {
  color: #303030;
}

.graph-two-ctn i {
  color: #933ec5;
}

.graph-three-ctn .text-info {
  color: #303030;
}



.graph-four-ctn .text-danger {
  color: #303030;
}

.graph-four-ctn i {
  color: #d80027;
}

.analytics-info-cs h3 {
  font-size: 20px;
}

.stats-row {
  margin-bottom: 20px;
}

.stat-item {
  display: inline-block;
  padding-right: 15px;
}

.single-new-trend {
  position: relative;
  transition: all 0.3s ease 0s;
}

.single-new-trend>a,
.product-tabs a,
.widget-image>a,
.single-brand>a,
.blog-img>a {
  display: block;
}

.single-new-trend img,
.single-new-trend img {
  width: 100%;
}

.featured-product-list .overlay-content h2 {
  font-size: 18px;
  left: 20px;
}

.overlay-content h2 {
  color: #5a5a5a;
  font-size: 20px;
  font-weight: 700;
  left: 10px;
  position: absolute;
  top: 10px;
  transition: all 0.3s ease 0s;
}

.btn-small {
  background: #24caa1;
  color: #fff !important;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  text-align: center;
  text-transform: uppercase;
  top: 10px;
  border-radius: 2px;
}

.featured-product-list .overlay-content .product-action {
  bottom: 45px;
}

.overlay-content .product-action {
  bottom: 40px;
  left: 50%;
  position: absolute;
  margin-left: -43px;
}

.product-action>ul,
#checkout-review-table thead,
.single-brand {
  text-align: center;
}

.product-action li,
.all-adress-info,
.product-attributes>span,
.left-content span,
.shop-tab-pill ul li,
.team-member>a,
.footer-left>a {
  display: inline-block;
}

.featured-product-list .product-action a {
  height: 28px;
  line-height: 26px;
  width: 28px;
}

.product-action a {
  border: 1px solid #ddd;
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.featured-product-list .overlay-content h4 {
  left: 20px;
}

.overlay-content h4 {
  bottom: 10px;
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 700;
  left: 10px;
  margin: 0;
  position: absolute;
  transition: all 0.3s ease 0s;
  font-size: 15px;
}

.pro-rating {
  bottom: 8px;
  position: absolute;
  right: 10px;
}

.widget-head-info-box {
  width: 100%;
  height: 240px;
  color: #fff;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

.personal-info-ano .widget-head-info-box {
  background: #2eb7f3;
}

.widget-head-info-box {
  padding: 20px;
  text-align: center;
}

.stat-item h6 {
  font-size: 12px;
  margin-bottom: 5px;
}

.analytics-rounded-content h2,
.persoanl-widget-hd h2,
.fc-center h2 {
  font-size: 20px;
}

.widget-text-box p {
  font-size: 14px;
  line-height: 24px;
}

.widget-text-box {
  margin-top: 10px;
}

.author-wiget-inner {
  text-align: center;
  padding: 20px;
}

.fc-content .fc-title {
  color: #fff;
  padding: 5px 5px;
  display: inline-block;
  font-size: 14px;
}

.fc-content .fc-time {
  color: #fff;
  font-size: 14px;
}

.fc button,
.fc-button-group,
.fc-time-grid .fc-event .fc-time span {
  display: block;
}

.header-top-area {
  position: fixed;
  z-index: 99;
  left: 219px;
  right: 0;
  top: 1px;
  margin: 0 auto;
  transition: all 0.3s;
}

.jqstooltip {
  width: auto !important;
  height: auto !important;
}

.mini-navbar .header-top-area {
  left: 80px;
}

.breadcome-list.single-page-breadcome {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mg-b-pro-edt {
  margin-bottom: 15px;
}

.custom-product-edit {
  margin-top: 20px;
}

.product-edt-pix-wrap {
  padding: 40px 0px;
}

.pro-edt-img img {
  width: 60%;
}

.product-edt-remove button {
  margin-top: 20px;
  background-color: #e12503;
  border-color: #e12503;
}

/*----------------------------------------*/
/*  13.1 Courses Info
/*----------------------------------------*/
.blog-box {
  background: #fff;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.blog-std {
  padding-top: 15px;
}

.blog-std p {
  margin: 2px 0px;
}

.custom-blog-hd {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.blog-std p {
  margin: 2px 0px;
}

.blog-std {
  padding-top: 15px;
}

.blog-pra p {
  margin: 0;
}

.blog-img img {
  margin-bottom: 10px;
}

.blog-image {
  position: relative;
}

.blog-image img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.blog-date {
  background: var(--primary-color);
  border: 4px solid #fff;
  border-radius: 50%;
  bottom: -25px;
  color: #fff;
  height: 70px;
  left: 0;
  line-height: 15px;
  position: absolute;
  text-align: center;
  width: 70px;
}

.blog-date p {
  font-weight: 400;
  margin: 0;
  padding: 7px 10px;
  font-size: 16px;
  display: block;
  line-height: 20px;
}

.blog-date .blog-day {
  font-size: 14px;
}

.details-blog-dt {
  margin-left: 60px;
  margin-bottom: 20px;
}

.details-blog-dt a,
.details-blog-dt a:hover,
.details-blog-dt a:focus {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.blog-details a.blog-ht,
.blog-details a.blog-ht:hover,
.blog-details a.blog-ht:focus {
  font-size: 20px;
  font-weight: 700;
  color: #303030;
  text-transform: capitalize;
  text-decoration: none;
}

.blog-details p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.comment-head h3::before,
.lead-head h3:before {
  background: #e7e7e7 none repeat scroll 0 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
}

.comment-head h3::before,
.comment-head h3::after {
  width: 37%;
}

.comment-head h3::after,
.lead-head h3:after {
  background: #e7e7e7 none repeat scroll 0 0;
  content: "";
  height: 1px;
  right: 0;
  position: absolute;
  top: 50%;
}

.user-comment {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 15px;
}

.user-comment img {
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.comment-head h3 {
  margin: 20px 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}

.comment-head h3,
.comment-details h4,
.lead-head h3 {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
}

.blog-sig-details-dt span {
  margin: 10px 80px 0;
  display: inline-block;
}

.comment-details p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.comment-replay {
  float: right;
  color: var(--primary-color);
  font-size: 14px;
  text-transform: uppercase;
}

.lead-head h3 {
  text-transform: uppercase;
  position: relative;
  text-align: center;
}

.lead-head h3:before,
.lead-head h3:after {
  width: 30%;
}

.comment-head h3::before,
.lead-head h3:before {
  background: #e7e7e7 none repeat scroll 0 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
}

.lead-head h3:before,
.lead-head h3:after {
  width: 30%;
}

.comment-head h3::after,
.lead-head h3:after {
  background: #e7e7e7 none repeat scroll 0 0;
  content: "";
  height: 1px;
  right: 0;
  position: absolute;
  top: 50%;
}

.user-comment {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 15px;
}

.admin-comment {
  padding: 20px 0px;
  padding-left: 40px;
}

.user-coment2 {
  border-bottom: none;
  padding: 20px 0px;
}

.coment-area input[type="text"] {
  height: 40px;
}

.coment-area input[type="text"],
.coment-area textarea {
  width: 100%;
  border: 1px solid #e7e7e7;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 10px;
  outline: none;
}

.coment-area input[type="text"]:focus,
.coment-area textarea:focus {
  border: 1px solid var(--primary-color);
}

.coment-area input[type="submit"] {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  padding: 10px 30px;
  background: var(--primary-color);
  border: none;
  border-radius: 2px;
  transition: all 0.4s ease 0s;
  outline: none;
}

.coment-area textarea {
  padding-top: 10px;
}


.more-details {
  color: var(--primary-color);
  width: fit-content;
  text-decoration: underline;
}


/*----------------------------------------*/
/*  15. Inbox
/*----------------------------------------*/
.inbox-bt-mg {
  margin-bottom: 15px;
}

.mg-b-23 {
  margin-bottom: 23px;
}

.mg-b-20,
.mg-b-23 {
  margin-bottom: 20px;
}

ul.mailbox-list li.active a,
ul.h-list li.active a {
  background: #f7f9fa;
  border: 1px solid #e4e5e7;
  font-weight: 600;
  border-radius: 1px;
}

ul.mailbox-list li a,
ul.h-list li a {
  padding: 8px 15px;
  display: block;
  font-size: 14px;
  transition: all 0.4s ease 0s;
  color: #303030;
}

ul.mailbox-list li a:first-child {
  margin-top: 0px;
}

.mailbox-hd {
  background: #fff;
}

.mailbox-pagination {
  text-align: right;
}

.table.table-mailbox>tbody>tr>td,
.table.table-mailbox>tbody>tr>th,
.table.table-mailbox>tfoot>tr>td,
.table.table-mailbox>tfoot>tr>th,
.table.table-mailbox>thead>tr>td,
.table.table-mailbox>thead>tr>th {
  padding: 0px;
  vertical-align: middle;
  white-space: nowrap;
}

.table-mailbox tr.unread td a {
  font-weight: 600;
  font-size: 14px;
}

.panel-body a {
  font-size: 14px;
  color: #444;
  margin-top: 15px;
  transition: all 0.4s ease 0s;
}

.contact-footer,
.panel-footer {
  background: #f6f8fa;
}

.active-hook .btn-default {
  transition: all 0.4s ease 0s;
  outline: none;
}

.active-hook .btn-default:active {
  transition: all 0.4s ease 0s;
  outline: none;
}

.active-hook .btn-default:hover,
.active-hook .btn-default:active,
.active-hook .btn-default:focus {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
  transition: all 0.4s ease 0s;
}

.hpanel.email-compose {
  background: #fff;
  padding: 20px;
}

.hpanel .panel-body.panel-csm {
  border: 0px solid rgba(0, 0, 0, 0.12);
}

.mailbox-view .panel-heading,
.hpanel.email-compose .panel-heading {
  padding: 0px 0px 10px 0px;
}

.panel-heading.hbuilt.mailbox-hd {
  padding: 20px 20px 0px 20px;
}

.table.table-hover.table-mailbox {
  margin-bottom: 0px;
}

.custom-address-mailbox {
  margin-bottom: 20px;
}

.file-body i.fa {
  font-size: 40px;
  padding: 20px 0px;
}

.incon-ctn-view {
  text-align: center;
}

.btn-success.compose-btn {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
  margin-top: 0px;
}

.panel-body.no-padding {
  box-shadow: none;
}

.panel-body p {
  font-size: 14px;
  color: #303030;
  line-height: 24px;
}

.btn-primary.ft-compse {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.cts-ml-in {
  margin-top: 20px;
}

.cts-ml-in input[type="text"] {
  font-size: 14px;
  padding: 6px 15px;
  display: block;
}

.cts-ml-in .btn-default {
  padding: 8px 15px;
  color: #303030;
  font-size: 14px;
}

.ib-btn-gp button {
  font-size: 14px;
  color: #303030;
}

.ib-tb .label-warning {
  background: var(--primary-color);
}

.ib-tb .label-info {
  background: #933ec5;
}

.ib-tb .label-danger {
  background: #d80027;
}

.ib-ml-ft {
  font-size: 14px;
  color: #fff;
  background: var(--primary-color);
}

ul.mailbox-list li i {
  margin-right: 5px;
}

.input-sm {
  font-size: 14px;
}

.compose-multiple-email .multipleInput-container {
  height: 55px;
  padding: 5px;
}

.multipleInput-container {
  border: 1px #ccc solid;
  padding: 1px;
  padding-bottom: 0;
  cursor: text;
  font-size: 13px;
  width: 100%;
  height: 40px;
  overflow: auto;
  background-color: white;
  border-radius: 0px;
  padding: 0px 10px;
}

.multipleInput-container ul {
  list-style-type: none;
  padding-left: 0px !important;
}

.multipleInput-container input {
  font-size: 13px;
  width: 150px;
  height: 24px;
  border: 0;
  margin-bottom: 1px;
  outline: none;
}

.email_search {
  width: 100% !important;
}

li.multipleInput-email {
  float: left;
  margin-right: 2px;
  margin-bottom: 1px;
  border: 1px var(--primary-color) solid;
  background: var(--primary-color);
  color: #fff;
  padding: 4px 10px;
  border-radius: 2px;
}

li.multipleInput-email a {
  background: none;
  color: #fff;
  margin-left: 4px;
  display: inline-block;
  padding: 0px 1px;
}

.form-horizontal .control-label {
  padding-top: 10px;
}

.panel-body .note-editor.note-frame.panel.panel-default {
  margin-bottom: 0px;
}

.panel-body .dropmail {
  margin-bottom: 20px;
  margin-top: 20px;
}

.dropzone.dz-clickable {
  margin-bottom: 20px;
}

.dropmail .dropzone.dz-clickable .dz-message {
  border: 1px #fff solid;
}

.dropmail .download-custom i {
  color: #999;
  font-size: 30px;
}

.dropmail .download-custom h2 {
  color: #999;
  font-size: 16px;
}

.dropmail .download-custom p {
  color: #999;
}

.dropmail .dropzone.dropzone-custom {
  border: 2px dashed #999;
}

.contact-panel-cs .contact-footer,
.contact-panel-cs .panel-footer {
  background: var(--primary-color);
}

.form-control.dt-tb {
  height: 35px;
}

.ft-pn {
  background: #f6f8fa;
}

.ft-pn a {
  font-size: 14px;
  color: #303030;
}

td.text-right.mail-date {
  padding-right: 15px !important;
}

.static-table-list .table {
  margin-bottom: 0px;
}

.hpanel.email-compose .panel-body {
  padding: 0px;
}

/*----------------------------------------*/
/*  16. Google Map
/*----------------------------------------*/
#map,
#map1,
#map2,
#googleMap,
#maplan,
#map6,
#map7,
#map8 {
  height: 100%;
}

.google-map-single {
  width: 100%;
  height: 300px;
}

/*----------------------------------------*/
/*  17. Images Cropper
/*----------------------------------------*/
.img-preview-custom {
  height: 130px;
  width: 200px;
  overflow: hidden;
}

.dual-list-box-inner .other-method {
  margin-top: 15px;
}

.images-cropper-pro .btn-primary {
  background-color:var(--primary-color);
  border-color: var(--primary-color);
}

.images-cropper-pro .btn-primary:active:focus,
.images-cropper-pro .btn-primary:active:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.images-action-pro .btn-warning {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.images-action-pro .btn-warning:active,
.images-action-pro .btn-warning:focus,
.images-action-pro .btn-warning:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.img-preview-custom {
  height: 130px;
  width: 200px;
  overflow: hidden;
}

.img-croper-fl {
  display: flex;
}

.common-pre-dz {
  margin-left: 15px;
}

.images-cropper-pro .btn {
  margin: 5px 0px;
}

/*----------------------------------------*/
/*  18. Form Element
/*----------------------------------------*/
.sparkline8-graph .basic-login-inner,
.sparkline8-graph .login-social-inner,
.sparkline9-graph .basic-login-form-ad,
.sparkline11-graph .basic-login-form-ad {
  text-align: left;
}

.form-group-inner {
  margin-bottom: 15px;
}

.form-group-inner label {
  font-size: 14px;
  color: #303030;
}

.form-group-inner input[type="email"],
.form-group-inner input[type="password"],
.form-group-inner input[type="text"],
.form-select-list .custom-select-value {
  font-size: 14px;
  color: #303030;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 0px;
  box-shadow: none;
}

.form-group-inner .form-control {
  box-shadow: none !important;
  border-radius: 0px;
}

.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/img/green1.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-green {
  background-position: 0 0;
}

.icheckbox_square-green.hover {
  background-position: -22px 0;
}

.icheckbox_square-green.checked {
  background-position: -44px 0;
  transition: all 0.3s ease 0s;
}

.login-btn-inner .check-label {
  margin-left: 5px;
}

.login-btn-inner .label-check-inner {
  margin: 0px;
  padding-top: 5px;
}

.login-social-inner {
  margin-bottom: 20px;
}

.login-social-inner a.button {
  line-height: 42px;
  text-decoration: none;
  margin-right: 5px;
}

.login-social-inner .btn-social {
  position: relative;
}

.login-social-inner .span-left {
  padding-left: 55px;
  padding-right: 15px;
}

.login-social-inner .facebook {
  background-color: #3b5998;
}

.login-social-inner .twitter,
.login-social-inner .twitter:hover,
.login-social-inner .twitter:focus,
.login-social-inner .facebook,
.login-social-inner .facebook:hover,
.login-social-inner .facebook:focus,
.login-social-inner .googleplus,
.login-social-inner .googleplus:hover,
.login-social-inner .googleplus:focus,
.login-social-inner .linkedin,
.login-social-inner .linkedin:hover,
.login-social-inner .linkedin:focus {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

.login-social-inner .button {
  border: 0;
  height: 42px;
  color: #fff;
  line-height: 1;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}

.login-social-inner .button {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: none;
}

.login-social-inner .facebook:hover,
.login-social-inner .facebook:focus {
  background-color: #25385f;
}

.login-social-inner .facebook span {
  background-color: #31497d;
}

.login-social-inner .btn-social span {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 42px;
  left: 0;
}

.login-social-inner .btn-social i {
  font-size: 22px;
  position: relative;
  top: 2px;
}

.login-social-inner .twitter span {
  background-color: #009ad5;
}

.login-social-inner .twitter {
  background-color: #00acee;
}

.login-social-inner .twitter:hover,
.login-social-inner .twitter:focus {
  background-color: #00749f;
}

.login-social-inner .googleplus span {
  background-color: #c03121;
}

.login-social-inner .googleplus:hover,
.login-social-inner .googleplus:focus {
  background-color: #8d2418;
}

.login-social-inner .googleplus {
  background-color: #dd4b39;
}

.login-social-inner .linkedin span {
  background-color: #084261;
}

.login-social-inner .linkedin:hover,
.login-social-inner .linkedin:focus {
  background-color: #084261;
}

.login-social-inner .linkedin {
  background-color: #0077b5;
}

.login-horizental {
  margin-top: 20px;
}

.form-group-inner label.login2 {
  margin: 0px;
  font-weight: 600;
  padding: 7px 0px;
  font-size: 12px;
}

.inline-basic-form .form-group-inner {
  margin-bottom: 0px;
}

.modal-login-form-inner {
  text-align: left;
}

.modal-edu-general .modal-body .modal-basic-inner p {
  margin-bottom: 15px;
}

.bt-df-checkbox {
  padding: 8px 0px;
  margin: 0;
}

.bt-df-checkbox .radio-checked {
  margin-right: 8px;
}

.icheckbox_square-green.checked {
  background-position: -44px 0;
}

.icheckbox_square-green.checked.disabled {
  background-position: -88px 0;
}

.iradio_square-green {
  background-position: -109px 0;
}

.iradio_square-green.hover {
  background-position: -131px 0;
}

.iradio_square-green.checked {
  background-position: -153px 0;
  transition: all 0.3s ease 0s;
}

.iradio_square-green.checked.disabled {
  background-position: -197px 0;
}

.inline-checkbox-cs {
  padding: 7px 0px;
}

.inline-checkbox-cs .checkbox-inline {
  padding-left: 0px;
}

.form-group-inner.input-with-success label {
  color: #1ab394;
}

.form-group-inner.input-with-success input[type="text"] {
  border: 1px solid #1ab394;
}

.form-group-inner.input-with-warning label {
  color: #f8ac59;
}

.form-group-inner.input-with-warning input[type="text"] {
  border: 1px solid #f8ac59;
}

.form-group-inner.input-with-error label {
  color: #ed5565;
}

.form-group-inner.input-with-error input[type="text"] {
  border: 1px solid #ed5565;
}

.inline-remember-me label {
  margin: 0px;
  padding: 5px 0px;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-btn .btn {
  padding: 10px 12px;
}

.form-bc-ele .btn {
  padding: 6px 12px;
  border-radius: 20px;
}

.input-group-addon {
  background-color: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.i-checks ul input {
  width: auto;
}

.input-group-addon {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 5px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  height: 40px;
  box-shadow: none;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: none;
}

.custom-go-button .btn-primary,
.custom-dropdowns-button .dropdown-toggle,
.dropdown-segmented .dropdown-toggle,
.dropdown-segmented .btn-white {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-go-button .btn-primary,
.custom-go-button .btn-primary:active,
.custom-go-button .btn-primary:focus,
.custom-dropdowns-button .dropdown-toggle,
.custom-dropdowns-button .dropdown-toggle:active,
.custom-dropdowns-button .dropdown-toggle:focus,
.dropdown-segmented .dropdown-toggle,
.dropdown-segmented .dropdown-toggle:active,
.dropdown-segmented .dropdown-toggle:focus {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color:var(--primary-color);
  border-color: var(--primary-color);
}

.dropdown-segmented .dropdown-toggle,
.dropdown-segmented .dropdown-toggle:active,
.dropdown-segmented .dropdown-toggle:focus {
  border-left: 1px solid var(--primary-color);
}

.fileupload {
  border: 1px dashed var(--primary-color);
  padding: 3px;
  font-size: 14px;
  max-width: 100%;
}

.cancel-wp .btn-white,
.cancel-wp .btn-white:active,
.cancel-wp .btn-white:focus {
  outline: none;
}

.file-upload-inner.ts-forms input[type="text"]:hover,
.file-upload-inner.ts-forms input[type="text"]:focus,
.file-upload-inner.ts-forms input[type="text"]:active,
.file-upload-inner.ts-forms .file-button:hover+input {
  border: 1px solid var(--primary-color);
}

.file-upload-inner.ts-forms input[type="text"] {
  height: 40px;
}

.file-upload-inner.ts-forms .file-button,
.file-upload-inner.ts-forms .file-button:active,
.file-upload-inner.ts-forms .file-button:focus {
  background: var(--primary-color);
  border-radius: 0px;
  width: 70px;
  height: 40px;
  line-height: 40px;
  top: 0px;
}

.file-upload-inner.ts-forms .prepend-small-btn .file-button,
.file-upload-inner.ts-forms .prepend-big-btn .file-button {
  left: 0px;
}

.file-upload-inner.ts-forms .prepend-big-btn input[type="text"] {
  padding-left: 81px;
}

.file-upload-inner.ts-forms .icon-left,
.file-upload-inner.ts-forms .icon-right {
  top: 0px;
}

.file-upload-inner.file-upload-inner-right.ts-forms .file-button,
.file-upload-inner.file-upload-inner-right.ts-forms .file-button:active,
.file-upload-inner.file-upload-inner-right.ts-forms .file-button:focus {
  right: 0px !important;
}

.file-upload-inner.file-upload-inner-right.ts-forms .append-small-btn .file-button input,
.file-upload-inner.file-upload-inner-right.ts-forms .append-big-btn .file-button input {
  right: 0px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #03a9f4;
}

.chosen-select-single label {
  font-weight: 400;
  font-size: 14px;
}

.spacer-b16a {
  text-align: left;
  margin-bottom: 5px;
}

.sliderv-wrapper.green-slider.green-left-pro {
  margin-left: 8px;
}

.sliderv-wrapper.black-slider.slider-bl-pro {
  margin-top: 22px;
}

.input-mask-title {
  text-align: right;
  padding: 10px 0px;
}

.input-mark-inner,
.data-custon-pick,
.touchspin-inner {
  text-align: left;
}

.input-mask-title label,
.input-mark-inner .help-block,
.data-custon-pick label,
.touchspin-inner label {
  font-size: 14px;
  font-weight: 400;
}

.input-mark-inner .form-control,
.data-custon-pick .form-control {
  border-radius: 0px;
}

.input-mark-inner .form-control:focus,
.input-mark-inner .form-control:active,
.data-custon-pick .form-control:focus,
.data-custon-pick .form-control:active {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}

.form-group.data-custon-pick.data-custom-mg {
  margin-bottom: 0px;
}

.datepicker table tr td.active.active,
.datepicker table tr td span.active.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ts-forms .tsbox {
  position: relative;
}

.ts-forms .label {
  font-size: 14px;
  margin-bottom: 15px;
  height: 14px;
  color: #303030;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 5px 0px;
}

.colorpicker-inner.ts-forms.mg-b-23 {
  margin: 14px 0px;
}

.ts-forms label {
  display: block;
  color: inherit;
  font-weight: normal;
  text-align: left;
  margin-bottom: 0;
}

.ts-forms {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  position: relative;
}

.ts-forms input[type="text"],
.ts-forms input[type="password"],
.ts-forms input[type="email"],
.ts-forms input[type="search"],
.ts-forms input[type="url"],
.ts-forms textarea,
.ts-forms select {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-family: inherit;
  font-size: 14px;
  height: 34px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.sp-replacer {
  background-color: #e0e0e0;

  border: none;
  bottom: 0;
  cursor: pointer;
  display: block;
  outline: none;
  padding-left: 16px;
  padding-top: 0px;
  position: absolute;
  right: 0;
  top: 0;
  width: 52px;
  color: rgba(0, 0, 0, 0.56);
}

.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sp-replacer {
  top: 20px !important;
  padding-left: 10px !important;
}

.sp-preview {
  position: relative;
  width: 25px;
  height: 20px;
  border: solid 1px #222;
  margin-right: 5px;
  float: left;
  z-index: 0;
  top: 5px;
}

.sp-dd {
  padding: 2px 0;
  height: 16px;
  line-height: 16px;
  float: left;
  font-size: 10px;
  margin-top: 5px;
}

.dual-list-box-inner option {
  padding: 0px 15px;
}

.inbox-email-menu-list .nav-tabs>li>a {
  border: 0px solid transparent;
}

.x-editable-list .table {
  margin-bottom: 0px;
}

.menu-list-mg-b.menu-list-wrap {
  margin-bottom: 40px;
}

.main-sparkline8-hd h1,
.main-sparkline9-hd h1,
.main-spark7-hd h1,
.main-sparkline10-hd h1,
.main-sparkline11-hd h1,
.main-sparkline12-hd h1,
.main-sparkline13-hd h1,
.main-sparkline14-hd h1 {
  font-size: 20px;
  color: #303030;
  font-weight: 700;
}

.main-sparkline13-hd h1 {
  padding-bottom: 10px;
  margin-left: 4px;
  width:99%;
  text-transform: capitalize;
  border-bottom: 1px solid #ccc;
}

.basic-choropleth {
  height: 400px;
  width: 100%;
}

.alert-title.dropzone-custom-sys h2,
.download-custom h2,
.alert-title h2,
.code-editor-single h2 {
  font-size: 18px;
  color: #303030;
}

.mini-navbar .charts-single-pro .bar-mini-ds {
  display: none;
}

.alert-title.dropzone-custom-sys p,
.download-custom p,
.alert-title p,
.sparkline-content p {
  font-size: 14px;
  color: #303030;
  line-height: 24px;
}

.sparkline-content a {
  font-size: 14px;
  color: #303030;
}

.preloader-single {
  background: #fff;
  width: 100%;
  height: 350px;
  padding: 15px;
}

.file-cs-ctn {
  text-align: center;
  padding: 30px 0px !important;
}

.custonfile .btn-primary {
  color: #fff;
  background-color: #86bc42;
  border-color: #86bc42;
}

.dropdown-menu.filedropdown {
  width: 100%;
}

.dropdown-menu.filedropdown li a {
  margin-top: 0px;
  padding: 8px 20px;
}

.dropdown-menu.filedropdown li i {
  margin-right: 5px;
}

.custom-panel-jw a {
  font-size: 18px;
}

.project-people img {
  width: 28px;
  height: 28px;
  margin-left: 2px;
  margin-bottom: 4px;
}

.panel-body a {
  font-size: 14px;
  color: #303030;
  margin-top: 15px;
}

.custom-panel-jw .social-media-in a {
  margin-top: 5px;
}

.project-value h2 {
  font-size: 25px;
  color: #444;
  margin-top: 10px;
}

.custom-project-dsl h2 {
  margin: 0;
  padding: 10px 15px;
  background: #ddd;
  font-size: 16px;
}

.m-t-none {
  font-size: 20px;
  color: #444;
}

.custom-address-mailbox div {
  margin: 5px 0px;
}

.panel-csm h4 {
  font-size: 18px;
  color: #303030;
}

.project-dtl-info h1 {
  font-size: 25px;
  color: #444;
}

.back-backend a {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.back-backend a:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.back-link {
  float: left;
  width: 100%;
  margin: 10px;
}

.content-error h1 {
  font-size: 55px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}

.content-error .counter {
  color: #fff;
}

.content-error {
  text-align: center;
  margin: 0px 20px;
}

.content-error p {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
}

.content-error a {
  display: inline-block;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color);
  padding: 10px 30px;
  border-radius: 3px;
  background: #fff;
  transition: all 0.4s ease 0s;
}

.content-error a:hover {
  color: var(--primary-color);
}

.login-footer a {
  color: #fff;
  text-decoration: underline;
}

.login-footer {
  margin-top: 15px;
}

.main-spark-hd h1,
.smart-main-spark-hd h1 {
  font-size: 20px;
}

.custom-datatable-overright table tbody tr td a {
  /* color: #303030;*/
  font-size: 14px;
}

.custom-datatable-overright table tbody tr td {
  padding-left: 10px !important;
  padding-right: 5px !important;
  font-size: 14px;
  text-align: left;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.fixed-table-pagination {
  padding: 0px 10px;
}

.tab-content-details h2 {
  font-size: 20px;
  color: #303030;
}

.tab-content-details {
  text-align: center;
  background: #fff;
  padding: 20px 100px;
}

.tab-content-details p {
  font-size: 14px;
  color: #303030;
  line-height: 24px;
  margin-bottom: 0px;
}

.color-line {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(left,
      var(--primary-color),
      var(--primary-color) 25%,
      #933ec5 25%,
      #933ec5 35%,
      #65b12d 35%,
      #65b12d 45%,
      #d80027 45%,
      #d80027 55%,
      #ffb606 55%,
      #ffb606 65%,
      #e67e22 65%,
      #e67e22 75%,
      #e74c3c 85%,
      #e74c3c 85%,
      #c0392b 85%,
      #c0392b 100%);
  background-image: -moz-linear-gradient(left,
      var(--primary-color),
      var(--primary-color) 25%,
      #933ec5 25%,
      #933ec5 35%,
      #65b12d 35%,
      #65b12d 45%,
      #d80027 45%,
      #d80027 55%,
      #ffb606 55%,
      #ffb606 65%,
      #e67e22 65%,
      #e67e22 75%,
      #e74c3c 85%,
      #e74c3c 85%,
      #c0392b 85%,
      #c0392b 100%);
  background-image: -ms-linear-gradient(left,
      var(--primary-color),
      var(--primary-color) 25%,
      #933ec5 25%,
      #933ec5 35%,
      #65b12d 35%,
      #65b12d 45%,
      #d80027 45%,
      #d80027 55%,
      #ffb606 55%,
      #ffb606 65%,
      #e67e22 65%,
      #e67e22 75%,
      #e74c3c 85%,
      #e74c3c 85%,
      #c0392b 85%,
      #c0392b 100%);
  background-image: linear-gradient(to right,
      var(--primary-color),
      var(--primary-color) 25%,
      #933ec5 25%,
      #933ec5 35%,
      #65b12d 35%,
      #65b12d 45%,
      #d80027 45%,
      #d80027 55%,
      #ffb606 55%,
      #ffb606 65%,
      #e67e22 65%,
      #e67e22 75%,
      #e74c3c 85%,
      #e74c3c 85%,
      #c0392b 85%,
      #c0392b 100%);
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.form-group-inner {
  margin-bottom: 15px;
}

.form-group-inner label {
  font-size: 14px;
  color: #303030;
}

.form-group-inner input[type="email"],
.form-group-inner input[type="password"],
.form-group-inner input[type="text"],
.form-select-list .custom-select-value {
  font-size: 14px;
  color: #303030;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 0px;
  box-shadow: none;
}

.form-group-inner input[type="email"]:focus,
.form-group-inner input[type="password"]:focus,
.form-group-inner input[type="text"]:focus,
.form-select-list .custom-select-value:focus {
  border: 1px solid var(--primary-color);
  outline: none;
  border-radius: 0px;
  box-shadow: none;
}

.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/img/green1.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-green {
  background-position: 0 0;
}

.icheckbox_square-green.hover {
  background-position: -22px 0;
}

.icheckbox_square-green.checked {
  background-position: -44px 0;
  transition: all 0.3s ease 0s;
}

.login-btn-inner .check-label {
  margin-left: 5px;
}

.login-btn-inner .label-check-inner {
  margin: 0px;
  padding-top: 5px;
}

.login-btn-inner .login-submit-cs {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  /* border-radius: 0px;*/
}

.modal-bootstrap.modal-login-form {
  padding: 7px 20px;
  background-color: var(--primary-color);
  display: inline-block;
  text-align: center;
}

.modal-bootstrap.modal-login-form a {
  color: #fff;
}

.login-btn-inner .btn-primary.active.focus,
.login-btn-inner .btn-primary.active:focus,
.login-btn-inner .btn-primary.active:hover,
.login-btn-inner .btn-primary:active.focus,
.login-btn-inner .btn-primary:active:focus,
.login-btn-inner .btn-primary:active:hover,
.login-btn-inner .btn-primary:hover,
.login-btn-inner .btn-primary:active,
.login-btn-inner .btn-primary:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  /*border-radius: 0px;*/
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
}

.create-account-sign a {
  color: var(--primary-color);
  font-size: 100px;
  padding: 5px 70px;
  display: block;
}

.login-social-inner {
  margin-bottom: 20px;
}

.login-social-inner a.button {
  line-height: 42px;
  text-decoration: none;
  margin-right: 5px;
}

.login-social-inner .btn-social {
  position: relative;
}

.login-social-inner .span-left {
  padding-left: 55px;
  padding-right: 15px;
}

.login-social-inner .facebook {
  background-color: #3b5998;
}

.login-social-inner .twitter,
.login-social-inner .twitter:hover,
.login-social-inner .twitter:focus,
.login-social-inner .facebook,
.login-social-inner .facebook:hover,
.login-social-inner .facebook:focus,
.login-social-inner .googleplus,
.login-social-inner .googleplus:hover,
.login-social-inner .googleplus:focus,
.login-social-inner .linkedin,
.login-social-inner .linkedin:hover,
.login-social-inner .linkedin:focus {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

.login-social-inner .button {
  border: 0;
  height: 42px;
  color: #fff;
  line-height: 1;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}

.login-social-inner .button {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: none;
}

.login-social-inner .facebook:hover,
.login-social-inner .facebook:focus {
  background-color: #25385f;
}

.login-social-inner .facebook span {
  background-color: #31497d;
}

.login-social-inner .btn-social span {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 42px;
  left: 0;
}

.login-social-inner .btn-social i {
  font-size: 22px;
  position: relative;
  top: 2px;
}

.login-social-inner .twitter span {
  background-color: #009ad5;
}

.login-social-inner .twitter {
  background-color: #00acee;
}

.login-social-inner .twitter:hover,
.login-social-inner .twitter:focus {
  background-color: #00749f;
}

.login-social-inner .googleplus span {
  background-color: #c03121;
}

.login-social-inner .googleplus:hover,
.login-social-inner .googleplus:focus {
  background-color: #8d2418;
}

.login-social-inner .googleplus {
  background-color: #dd4b39;
}

.login-social-inner .linkedin span {
  background-color: #084261;
}

.login-social-inner .linkedin:hover,
.login-social-inner .linkedin:focus {
  background-color: #084261;
}

.login-social-inner .linkedin {
  background-color: #0077b5;
}

.form-group-inner label.login2 {
  margin: 0px;
  padding: 10px 0px;
}

.bt-df-checkbox {
  padding: 8px 0px;
  margin: 0;
}

.bt-df-checkbox .radio-checked {
  margin-right: 8px;
}

.icheckbox_square-green.checked {
  background-position: -44px 0;
}

.icheckbox_square-green.checked.disabled {
  background-position: -88px 0;
}

.iradio_square-green {
  background-position: -109px 0;
}

.iradio_square-green.hover {
  background-position: -131px 0;
}

.iradio_square-green.checked {
  background-position: -153px 0;
  transition: all 0.3s ease 0s;
}

.iradio_square-green.checked.disabled {
  background-position: -197px 0;
}

.inline-checkbox-cs {
  padding: 7px 0px;
}

.inline-checkbox-cs .checkbox-inline {
  padding-left: 0px;
}

.checkbox.login-checkbox label {
  padding-left: 0px;
}

.btn-success.loginbtn {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.lock-inner i {
  font-size: 60px;
  color: var(--primary-color);
}

.lock-inner .text-success {
  color: var(--primary-color);
}

.lock-inner .btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.poss-recover .btn-success {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.main-sparkline16-hd h1,
.main-sparkline15-hd h1 {
  font-size: 20px;
}

.knob-single input {
  position: absolute;
  top: 0px;
  right: 25px;
}

.download-custom i {
  font-size: 40px;
}

.dropzone-custom-sys {
  text-align: center;
  padding: 30px 100px;
  background: #fff;
}

element.style {
  display: inline-block;
  width: 721px;
  height: 355px;
  vertical-align: top;
}

div#sparklinehome canvas {
  width: 100% !important;
}

.team-images {
  text-align: center;
}

.team-images img {
  width: 50px;
  height: 50px;
  margin: 0 6px;
}

.teams-section h4 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #5d5d5d;
  margin-top: 15px;
  display: inline-block;
}

.acknowledge-bg {
  background: #fff;
  box-shadow: 0 3px 2px #e3e3e3;
  border-radius: 4px;
  padding: 20px 20px 0;
}

.acknowledge-bg p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
  color: #a7a7a7;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.miles-text h4 {
  margin: 20px 0 18px 0 !important;
}

.product-buttons {
  display: inline-block;
  float: right;
  margin: 12px 0 0 0;
}

.i-checks-list li {
  padding: 11px 0 8px 10px;
  display: inherit !important;
  text-align: center;
}

.i-checks-list li .slider.round {
  display: none;
}

.notes-bg {
  border: 1px solid #d2d2d2;
  padding: 18px 5px 18px 5px;
  display: inline-block;
  margin: 0 10px 0 0;
  width: 171px;
}

.phones-image {
  margin: 139px 0 0 0;
}

.phones-image img {
  border-radius: 33px;
}

.switch-label {
  display: inline-block;
}

.modal-dialog-team .modal-header {
  border-bottom: 1px solid #e5e5e5;
}

.team-forms label {
  font-weight: normal;
  text-transform: uppercase;
  color: #c5c5c5;
  font-size: 10px;
}

.cancel-close {
  background: #e3e4e6;
  border-radius: 50px !important;
  color: #7b7b7b;
  border: 0;
}

.create-btns {
  background: #007aff;
  border-radius: 50px !important;
  color: #fff;
  border: 0;
}

.modal-dialog-team {
  margin: 134px auto 0;
}

.teams-section p {
  font-size: 14px;
  margin-bottom: 24px;
  color: #929292;
  margin-top: 15px;
}

.breadcome-list-home {
  margin: 75px 0 20px 0;
}

.edit-ta-resize textarea {
  height: 211px !important;
  resize: none !important;
}

.sidebar-header img {
  max-width: 183px;
  border-radius: 10px;
  margin: 20px 8px 13px 5px;
}

.mini-navbar .sidebar-header img {
  max-width: 76px;
  margin-left: -6px !important;
}

.header-top-area {
  background-color: white;
}

.sidebar-nav .metismenu {
  line-height: 2;
  background: #F3F3F3 !important;
  margin: 0 7px;
  max-height: calc(100vh - 120px);
}


.sidebar-nav .metismenu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar-nav .metismenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar-nav .metismenu::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
.sidebar-nav .metismenu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-nav {
  overflow-y: scroll;
  background: none;
}

.sidebar-nav ul {
 background: #F3F3F3;;
}

.fixed-table-toolbar .dropdown-menu {
  z-index: 11;
}

#sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle {
  position: absolute;
  right: -180px;
  top: 0px;
  text-align: left;
  width: 180px;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
}

#sidebar.active .sidebar-nav .metismenu a:hover,
#sidebar.active .sidebar-nav .metismenu a:focus,
#sidebar.active .sidebar-nav .metismenu a:active {
  background: #5a5a5a12 !important;
  border-radius: 10px;

}

.sidebar-nav .metismenu a.active {
  font-weight: 600;
  background: var(--icon-bg-color) !important;
  border-radius: 10px;
  color: var(--primary-color);
  i,div {
    font-weight: 600;
    color: var(--primary-color);
  }
}


.mCustomScrollBox {
  background: #f2f2f2;
}

#sidebar.active .mCustomScrollBox {
  overflow: unset;
}

#sidebar.active .mCSB_container {
  overflow: unset;
}

.sidebar-nav .metismenu ul a i {
  float: left;
  margin-top: 4px;
}

.sidebar-nav .metismenu a {
  color: #666666c4;
  max-width: 201px;
  padding: 5px 10px;
}

.metismenu ul li a i {
  display: none;
}

.sidebar-nav .metismenu ul a {
  padding: 5px 9px 5px 32px !important;
}

.sidebar-nav .metismenu ul a span {
  display: inline-block;
  width: 150px;
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active {
  background: #5a5a5a12 !important;
  border-radius: 10px;
}


.header-top-menu .navbar-nav>li .dropdown-menu,
.header-right-info ul.header-right-menu li .author-message-top,
.header-right-info ul.header-right-menu li .notification-author,
.header-right-info ul.header-right-menu li .author-log,
.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg.dropdown-menu {
  background: #fff;
}

.header-top-menu .navbar-nav>li .dropdown-menu a:hover,
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:hover,
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:focus,
.header-drl-controller-btn.btn-info:active:focus,
.btn-info:active:hover {
  background: var(--icon-bg-color);
}

.header-top-menu .navbar-nav>li.open>a {
  color: #fff;
}

.header-top-menu .navbar-nav>li>a:hover {
  color: #fff;
}

#sidebar {
  background: #F3F3F3;
}

#sidebar .sidebar-header {
  background: none;
  min-height: 70px;
}

#sidebar.active .sidebar-header {
  padding-top: 10px;
  position: relative;
  z-index: 9;
  padding-bottom: 10px;
}

.order-st i {
  background: #86bc42;
}

.product-details-btn button,
.product-details-btn-2 button {
  background: #86bc42;
}

.fixed-table-container thead th .th-inner,
.fixed-table-container tbody td .th-inner {
  padding: 8px 10px !important;
}

.single-product-text .button-default.cart-btn {
  background: var(--primary-color);
}

.history-anl-icon i {
  background-color: #86bc42;
}

.footer-copyright-area {
  background: #fff;
  padding: 15px 0px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 219px;
  right: 0;
  margin: auto;
  z-index: 999;
}

.export-btn button {
  background: var(--primary-color);
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  border: 0;
  padding: 6px 20px;
  border-radius: 50px;
}

.export-btn button:hover {
  background: #0a80ea;
}

.breadcome-heading2 {
  display: inline-block;
}

.export-btn {
  display: inline-block;
}

.breadcome-heading2 input {
  background: #fff !important;
}

.geo-fence h3 {
  font-weight: normal;
  font-size: 22px;
  color: #0a80ea;
}

.geo-fence p {
  font-weight: normal;
  font-size: 14px;
  color: #999;
  line-height: 23px;
}

.fremont {
  background: #fff;
  display: inline-block;
  width: 100%;
  margin: 15px 0 0 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  min-height: 260px;
  padding: 10px 0 0 0;
}

.fremont h5 {
  font-weight: normal;
  font-size: 18px;
  color: #5d5d5d;
}

.fremont h4 {
  font-weight: normal;
  font-size: 12px;
  color: #5d5d5d;
  margin: 0 0 7px 0;
}

.fremont i {
  font-size: 13px;
  color: #a8a8a8;
  cursor: pointer;
}

.metismenu li a i {
  font-size: 16px !important;
  vertical-align: middle;
  color: var(--icon-color);
  width: 26px;
}


.metismenu li a div {
  font-size: 18px !important;
  margin-left: -2px;
  vertical-align: middle;
  color: var(--icon-color);
  margin-top:3px;
  width: 26px;
}

.metismenu li a div.active{
  color: var(--primary-color);
}
.sidebar-nav .arrow-none a:after {
  display: none;
}

.sparkline13-list {
  margin: 37px 0 0px 0px;
}

.border-btm {
  border-bottom: 1px solid #e4e4e4;
  padding: 0 0 8px 0;
}

.inr-fremont {
  background: #fff;
  box-shadow: 0px 3px 3px #e3e3e3;
  overflow: hidden;
  padding: 0 0 10px 0;
}

.inr-fremont ul li {
  list-style: none;
  display: inline-block;
}

.inr-fremont ul li a {
  font-size: 12px;
  font-weight: normal;
  color: #807c7c;
}

.inr-fremont ul li:first-child {
  border-right: 1px solid #cecece;
  line-height: 10px;
  padding: 0 4px 0 0;
}

.icons-pencils {
  text-align: right;
}

.message-single-top h1,
.notification-single-top h1 {
  border-bottom: 1px solid #f6f8fa;
}

.header-right-info ul.header-right-menu li .message-view a,
.header-right-info ul.header-right-menu li .notification-view a {
  border-top: 1px solid #f6f8fa;
}

.header-right-info .author-message-top:before,
.header-right-info .notification-author:before {
  border-bottom: 10px solid #fff;
}

.header-right-info ul.header-right-menu li.open>a {
  color: #fff;
}

.indicator-ms {
  background: #fff;
}

.indicator-nt {
  background: #fff;
}

.nav-tabs {
  padding-bottom: 7px;
}

.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:focus,
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:hover .notes-img {
  background: var(--icon-bg-color);
}

.header-right-info .nav.custon-set-tab>li>a:focus,
.header-right-info .nav.custon-set-tab>li>a:hover,
.header-right-info .nav.custon-set-tab>li>a:active {
  color: #303030;
}

.edu-custon-design .panel-heading {
  background: var(--primary-color);
  color: #fff;
}

.panel-group.edu-custon-design .accordion-head a:hover,
.panel-group.edu-custon-design .accordion-head a:focus,
.panel-group.edu-custon-design .accordion-head a:active {
  color: #fff;
}

.analytics-sparkle-line .analytics-content .tuition-fees {
  font-size: 14px;
  float: right;
  margin-top: 8px;
  color: #999;
  font-weight: 300;
}

.storage-right {
  float: right;
  margin-bottom: 0px;
  margin-top: -25px;
  font-size: 16px !important;
}

.analysis-progrebar-content .progress {
  width: 100%;
  margin-bottom: 6px;
}

.single-item-rating {
  float: right;
  margin-top: -32px;
  color: #ffb463;
}

.single-item-rating i {
  font-size: 14px;
}

.product-price h3 {
  font-size: 25px;
}

.product-buttons button:hover {
  background: var(--primary-color);
  transition: all 0.4s ease 0s;
}

.single-product-text a,
.single-latest-text a {
  color: #444;
  display: block;
  font-size: 18px;
}

.single-product-text .cards-hd-dn {
  margin-top: 17px;
}

.single-product-text.edu-pro-tx a {
  margin-top: -1px;
}

.single-product-image img {
  width: 100%;
}

.single-product-text a:hover,
.single-latest-text a:hover {
  color: var(--primary-color);
  transition: all 0.4s ease 0s;
}

.single-product-text .button-default.cart-btn:hover {
  background: var(--primary-color);
  transition: all 0.4s ease 0s;
}

.single-latest-image {
  float: left;
}

.single-latest-text {
  background: #fff;
  overflow: hidden;
  padding: 10px 0 25px 30px;
}

.single-latest-text p {
  font-size: 14px;
  color: #444;
  margin: 10px 0px;
}

.single-latest-text .button-default {
  font-size: 14px;
  background: #86bc42;
  padding: 5px 10px;
  border-radius: 3px;
  transition: all 0.4s ease 0s;
  color: #fff;
  display: inline;
}

.single-latest-text .button-default:hover {
  background: #2d3e50;
  color: #fff;
}

.alert-up-pd {
  margin-top: 70px !important;
  margin-bottom: 40px !important;
}

.progress-skill .progress {
  height: 5px;
  margin-bottom: 10px;
}

.personal-gateway select {
  background: inherit;
  border: 1px solid #cbc7c7;
  padding: 6px 6px;
  margin: 0 0 25px 0;
  color: #9e9e9e;
}

.login-logo img {
  max-width: 180px;
}

.service-prvder h4 {
  font-weight: normal;
  color: #5d5c5c;
  margin: 10px 0 13px 0;
}

.service-prvder ul {
  margin: 0;
  padding: 0 0 0 16px;
}

.service-prvder ul li {
  list-style: none;
  display: inline-block !important;
  width: 32%;
  margin: 0 0 10px 0;
}

.gress-skill h2 {
  font-size: 16px;
  color: #444;
}

.service-prvder sup {
  color: red;
}

.progress-green {
  background-color: var(--primary-color);
}

.login-cancel {
  text-align: right;
  margin: 5px 20px 5px 5px;
}

.progress-yellow {
  background-color: #933ec5;
}



.progress-red {
  background-color: #d80027;
}

.blog-sig-details-dt.courses-info span {
  margin: 10px 50px 0;
  display: inline-block;
}

.social-media-edu,
.single-review-st-text {
  display: flex;
}

.single-review-st-text {
  padding: 7.7px 0px;
}

.single-review-st-text:last-child {
  padding-bottom: 0px;
}

.social-media-edu i {
  font-size: 40px;
  line-height: 54px;
  color: var(--primary-color);
}

.social-media-edu.twitter-cl i {
  color: #933ec5;
}


.social-media-edu.youtube-cl i {
  color: #d80027;
}

.social-edu-ctn {
  margin-left: 15px;
}

.social-edu-ctn h3 {
  font-size: 16px;
  text-transform: capitalize;
  color: var(--primary-color);
}

.twitter-cl .social-edu-ctn h3 {
  color: #933ec5;
}


.youtube-cl .social-edu-ctn h3 {
  color: #d80027;
}

.social-edu-ctn p {
  font-size: 14px;
  margin: 0;
  color: #303030;
}

.single-product-text img {
  width: 100px;
  height: 100px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  margin-top: -70px;
}

.single-product-text {
  padding: 20px;
  text-align: center;
}

.single-product-text.edu-pro-tx {
  padding: 20px 0px 0px 0px;
}

.single-product-text.edu-pro-tx h5 {
  line-height: 20px;
  margin-bottom: 5px;
  color: #444;
}

.single-cards-item,
.single-review-st-item,
.white-box {
  background: #fff;
  overflow: hidden;
}

.single-review-st-item,
.white-box {
  padding: 20px;
}

.single-product-text .ctn-cards {
  font-size: 14px;
  color: #444;
  line-height: 24px;
}

.single-product-text .follow-cards {
  font-size: 14px;
  color: #fff;
  background: var(--primary-color);
  display: inline-block;
  padding: 5px 20px;
  border-radius: 3px;
}

.single-product-text .follow-cards:hover {
  color: #fff;
}

.cards-dtn {
  margin-top: 27px;
}

.single-product-text .cards-hd-dn {
  font-size: 18px;
}

.single-product-text h5 {
  font-size: 14px;
  color: #999;
  font-weight: 300;
}

.cards-dtn h3 {
  font-size: 18px;
  color: #303030;
  margin: 0;
}

.cards-dtn p {
  font-size: 14px;
  color: #303030;
  margin: 0;
}

.single-review-st-text img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.review-ctn-hf h3 {
  font-size: 14px;
  color: #303030;
  margin-bottom: 5px;
}

.review-ctn-hf,
.review-item-rating {
  margin-left: 15px;
}

.review-ctn-hf p {
  font-size: 14px;
  color: #303030;
  margin: 0;
}

.single-review-st-hd h2 {
  font-size: 18px;
  color: #303030;
}

.review-item-rating i {
  font-size: 14px;
  margin-top: 15px;
  color: #ffb463;
}

.review-ctn-hf {
  margin-top: 5px;
}

.review-item-rating {
  text-align: right;
  margin-left: auto;
  margin-top: 5px;
}

.single-product-text.edu-pro-tx {
  text-align: left;
}

.label-purple {
  background-color: #9675ce;
}

.basic-list li {
  display: block;
  padding: 11.6px 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  line-height: 27px;
  color: #303030;
  font-size: 14px;
}

.basic-list li:last-child {
  border-bottom: 0px solid rgba(120, 130, 140, 0.13);
  padding-bottom: 0px;
}

.basic-list .label {
  margin-top: 0px;
  padding: 7px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.label-danger.label-1 {
  background: var(--primary-color);
}

.label-purple.label-2 {
  background: #933ec5;
}



.label-info.label-4 {
  background: #d80027;
}

.label-purple.label-7 {
  background: #555;
}

.white-box h3 {
  font-size: 16px;
  color: #444;
}

.country-state .progress {
  height: 4px;
  margin-bottom: 0px;
}

.country-state h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 2px;
}

.country-state li {
  margin: 26px 0px;
}

.country-state small {
  font-size: 14px;
  color: #303030;
}

.white-box.analytics-info-cs h3 {
  font-size: 16px;
  color: #303030;
}

.analytics-content .progress {
  height: 5px;
  margin-bottom: 0;
}

.analytics-content .text-success {
  color: var(--primary-color);
}

.analytics-content .text-danger {
  color: #933ec5;
}



.analytics-content .text-inverse {
  color: #d80027;
}

.progress-bar.progress-bar-danger {
  background: #933ec5;
}



.progress-bar.progress-bar-inverse {
  background: #d80027;
}

.progress-bar.progress-bar-danger.hd-tp-1 {
  background: var(--primary-color);
}

.projects-st-content.project-rating-cl .progress-bar.hd-tp-2 {
  background: #933ec5;
}



.projects-st-content.project-rating-cl2 .progress-bar.hd-tp-4 {
  background: #d80027;
}

.progress-bar.progress-bar-danger.hd-tp-5 {
  background: #9675ce;
}

.projects-st-content.project-rating-cl .progress-bar.hd-tp-6 {
  background: #555;
}

.cus-product-sl-rp li h5 {
  color: #999;
  font-weight: 300;
}

.menu-switcher-pro .btn-info:active,
.menu-switcher-pro .btn-info:focus,
.menu-switcher-pro .btn-info:hover,
.menu-switcher-pro .btn-info:visited,
.header-drl-controller-btn.btn-info:active:focus {
  background: var(--primary-color);
  outline: none;
}

.mini-navbar .single-review-st-text img {
  height: 56px;
  width: 56px;
}

.mini-navbar .single-product-text img {
  width: 116px;
  height: 116px;
}
.widget-cl-1 .text-success {
  color: var(--primary-color);
}

.widget-cl-2 .text-info {
  color: #933ec5;
}



.widget-cl-4 .text-danger {
  color: #d80027;
}

.btn-success.widget-btn-1:active,
.btn-success.widget-btn-1:hover,
.btn-success.widget-btn-1:focus,
.btn-success.widget-btn-1:visited {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  outline: none;
}

.btn-info.widget-btn-2:active,
.btn-info.widget-btn-2:hover,
.btn-info.widget-btn-2:focus,
.btn-info.widget-btn-2:visited {
  background: #933ec5;
  border: 1px solid #933ec5;
  outline: none;
}

.btn-info.widget-btn-2 {
  background: #933ec5;
  border: 1px solid #933ec5;
}


.add-professors .invalid,
.addcourse .invalid,
.comment .invalid,
.addcoursepro .invalid,
.add-department .invalid {
  color: #d80027;
}

.form-group textarea {
  width: 100%;
  height: 134px;
  padding: 10px;
  border: 1px solid #e5e6e7;
  outline: none;
  line-height: 24px;
}

.form-group.edit-ta-resize textarea {
  height: 144px;
}

.form-group textarea:focus {
  border: 1px solid var(--primary-color);
}

.add-professors .form-group,
.addcoursepro .form-group {
  margin: 10px 0px;
}

.add-professors .file-upload-inner.ts-forms input[type="text"] {
  margin: 10px 0px;
}

.m-t-md .btn-success {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.m-t-md .btn-primary {
  background-color: #933ec5;
  border-color: #933ec5;
}



.addcourse .form-group {
  margin: 10px 0px;
}

.pro-ad .dropzone.dropzone-custom {
  border: 0px dashed #86bc42;
}

.dropzone.dz-clickable .dz-message {
  border: 2px dashed #ccc;
  margin: 0px 0px;
  padding: 20px;
}

.pro-ad .dropzone.dz-started .dz-message {
  display: block;
  height: 175px;
}

.pro-ad .dropzone.dz-started .edudropnone {
  display: none;
}

.pro-ad .dropzone .dz-preview {
  position: absolute;
  bottom: 115px;
}

.pro-ad .download-custom i {
  color: #999;
  font-size: 25px;
  margin-bottom: 10px;
}

.pro-ad .download-custom h2,
.pro-ad .download-custom p {
  font-size: 14px;
  color: #999;
}

.pro-ad .dropzone {
  padding: 0px;
}

.pro-ad .form-group textarea {
  height: 134px;
}

.pro-ad.addcoursepro .form-group textarea {
  height: 234px;
}

.hd-pro-img {
  overflow: hidden;
  opacity: 0;
}

.alert-up-pd .invalid {
  position: relative;
  top: 10px;
}

.pro-ad .dropzone.dz-clickable {
  cursor: auto;
}

.pro-ad .dropzone .download-custom {
  cursor: pointer;
}

.payment-adress .waves-effect.waves-light {
  cursor: poiner;
}

.comment .form-group {
  margin-bottom: 0px;
}

.comment input[type="text"],
.comment textarea {
  margin: 15px 0px;
}

.payment-adress.comment-stn {
  text-align: left;
}

.add-department .form-group {
  margin: 10px 0px;
}

.datatable-dashv1-list .form-control {
  height: 35px;
}

.datatable-dashv1-list .btn-default {
  outline: none;
}

.datatable-dashv1-list .editable-submit {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.datatable-dashv1-list .btn-default:hover,
.datatable-dashv1-list .btn-default:focus,
.datatable-dashv1-list .btn-default:active,
.datatable-dashv1-list .editable-submit:hover,
.datatable-dashv1-list .editable-submit:focus,
.datatable-dashv1-list .editable-submit:active {
  background: var(--primary-color);
  color: #fff;
}

.dropdown-segmented .btn {
  padding: 9px 12px;
}

.multi-uploader-cs .dropzone.dropzone-custom {
  border: 2px dashed var(--primary-color);
}

.multi-uploader-cs .dropzone.dz-clickable .dz-message {
  border: 0px dashed var(--primary-color);
}

.custom-login h3,
.ps-recovered h3 {
  font-size: 20px;
  color: #303030;
}

.custom-login p,
.ps-recovered p {
  font-size: 14px;
  color: #303030;
}

.footer-lg-pro a {
  font-size: 14px;
  color: var(--primary-color);
}

.country-state li:last-child {
  margin-bottom: 0px;
}

.pwstrength_viewport_progress .progress,
.pwstrength_viewport_progress2 .progress,
.pwstrength_viewport_progress4 .progress,
.form-group.mg-b-pass {
  margin-bottom: 0px;
}

/*----------------------------------------*/
/*  19. Mini Sidebar
/*----------------------------------------*/
/*#sidebar.active .sidebar-nav .metismenu {
    margin-top: 12px;
}*/
.sidebar-nav .metismenu ul a {
  word-wrap: break-word;
  max-width: 201px;
}

.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle.interface-mini-nb-dp {
  top: -30px;
}

.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle.chart-mini-nb-dp {
  top: -40px;
}

.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle.form-mini-nb-dp {
  top: -100px;
}

.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle.app-mini-nb-dp {
  top: -170px;
}

.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle.page-mini-nb-dp {
  top: -210px;
}

.sparkline-content p {
  line-height: 27px;
}

.sparkline-content.peity-ctn p {
  line-height: 26px;
}

.mini-navbar .sparkline-content p {
  font-size: 15px;
  line-height: 29px;
}

.form-group-inner:last-child {
  margin-bottom: 0px;
}

.note-editor.note-frame.panel.panel-default {
  margin-bottom: 0px;
}

.custon-tab-style1 p:last-child,
.edu-custon-design .admin-panel-content p:last-child {
  margin-bottom: 0px;
}

.edu-tab1 .nav-tabs {
  border-bottom: 0px solid #ddd;
}

/*----------------------------------------*/
/*  20. Custom Scroll
/*----------------------------------------*/
.comment-scrollbar,
.timeline-scrollbar,
.messages-scrollbar,
.project-list-scrollbar {
  height: 500px;
}

.mCSB_outside+.mCSB_scrollTools {
  right: -15px;
  margin: 0px 0px;
}

.message-menu .mCSB_outside+.mCSB_scrollTools,
.notification-menu .mCSB_outside+.mCSB_scrollTools,
.left-custom-menu-adp-wrap .mCSB_outside+.mCSB_scrollTools {
  right: 0px;
  margin: 0px 0px;
}

.mCS-light-1.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: var(--primary-color);
}

.mCS-light-1.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #ccc;
}


.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 21px;
}

.mat-form-field-wrapper {
  padding-bottom: 0.3em;
}

.cursor-pointer {
  cursor: pointer;
}

.inner-tabs-right {
  display: none;
  position: absolute;
  width: 100%;
  background: #f2f2f2;
  left: 0;
}

.active+.inner-tabs-right {
  display: flex;
}

.tabling-boxt6 .nav>li>a {
  font-weight: 500;
  padding: 7px 12px;
  font-size: 14px;
  ;
}



.caption {
  width: auto !important;
}

.modl-nabs {
  background: #f2f2f2;
  margin-bottom: 0px;
  position: relative;
}

.modal-form-part {
  padding: 20px 20px 0px 20px;
}

.common-modal .modl-nabs>li>a.active,
.common-modal .modl-nabs>li a:hover,
.common-modal .modl-nabs>li>a.active:focus {
  border-bottom: 2px solid var(--primary-color) !important;
  color: #505461 !important;
}

.modl-nabs>li>a {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #505461 !important;
  cursor: pointer;
  font-size: 14px !important;
  padding: 8px 10px;
  font-weight: 500;
  display: block
}

.inner-tabs-pro {
  display: none;
  background: #f2f2f2;
  z-index: 99;
  position: absolute;
}

.inner-tabs-pro>li>a {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #505461 !important;
  cursor: pointer;
  font-size: 15px;
  padding: 8px 10px;
  font-weight: 500;
  display: block
}

.inner-tab-consumable-pro {
  width: 100%;
  left: 0px;
}

.modl-nabs>li>a.active+.inner-tabs-pro {
  display: flex
}

.opacity {
  opacity: 1;
}

.strokeBtn {
 background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    color: var(--primary-color);
    padding: 6px 10px 6px 10px;
}

.searchEffects {
  padding: 15px 10px !important;
  position: sticky !important;
  top: -1px !important;
  background: gainsboro !important;
  z-index:9 !important;
  color: var(--primary-color) !important;
  border-bottom:1px solid var(--primary-color) !important;
}
.searchEffects::placeholder {
  color: var(--primary-color) !important;
}

.modal-Align {
  margin-left: 14rem;
}

.dateFormat {
  input {
    font-size: 14px;
  }
}

.modal-header-style {
  color: black;
  border-bottom: 2px solid #dcdcdc59;
  position: sticky;
  top: 0;
  z-index: 9;
  background: var(--primary-color) !important;
  justify-content: space-between;

  .icon {
    background: white;
    border-radius: 20px;
    height: 1.8rem;
    width: 1.9rem;

    .icon-style {
      font-size: 20px;
      color: var(--primary-color);
      padding: 4px 0px 0px 8px;
    }
  }


  h5 {
    margin-right: 10px;
    color: white !important
  }
}

.icon-style {
  font-size: 20px;
  height: 1.6rem;
  width: 1.5rem;
  text-align: center;
  border-radius: 3px;
  padding-top: 3px;
}
.mdc-menu-surface.mat-mdc-select-panel {
  padding:0px !important;
}
.ad-text-size {
  font-size: 14px !important;
}
.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}
.ellipsis-text{
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

mat-label {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}

input {
  padding-bottom: 1px !important;
}
.swal2-icon svg path {
  fill: var(--primaryColor); /* Change the color of the icon */
}

@media screen and (max-width: 1000px) {
  .sparkline13-list {
    margin: 37px 0 0px 0px;
    padding-left:37px;
  }
}
